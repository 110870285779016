import { Button } from '@yes.technology/react-toolkit'

import useDynamicSVGImport from './useDynamicSVGImport'
import ytasymbolclasses from '../mocks/yta-mock/ytasymbolclasses.json'

export const Symbol = ({ symbol, click }) => {
  /*
    WIP:
    - Traço diagonal após clique no símbolo (YTA Oficial)
    - classe de erros sucesso, omissão e erro (YTA Prática)

    OBS.:
    - Importação dinâmica de mock SVG temporária. Todos os símbolos devem ser
    importados e guardados na memória antes do teste iniciar
  */
  const handleClick = () => {
    click?.()
  }

  const symbolclass = Object.keys(ytasymbolclasses).find((k) =>
    ytasymbolclasses[k].includes(symbol?.uuid_Object)
  )

  const { error, loading, SvgIcon } = useDynamicSVGImport(
    `${symbolclass}/${symbol?.uuid_Object}`
  )

  if (error) {
    return error.message
  }

  if (loading) {
    return '...'
  }

  if (SvgIcon) {
    return (
      <>
        <Button
          heightClass='xSmall'
          impactClass='navigation'
          textColor='black'
          variant='secondary'
          onClick={handleClick}
          className='yta-btn-symbol'
        >
          <SvgIcon />
        </Button>
      </>
    )
  }

  return null
}

export default Symbol
