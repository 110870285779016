import { DataFieldTextArea } from '@yes.technology/react-toolkit'

function ActivityInputArea({
  id,
  cssClass,
  readOnly,
  required,
  value,
  onChange,
  label,
  helpText,
  explanationText
}) {
  const handleValue = (value) => {
    onChange(value)
  }

  return (
    <DataFieldTextArea
      spacingClass='medium'
      onChange={handleValue}
      value={value}
      id={id}
      informational={readOnly}
      required={required}
      className={`${readOnly ? '' : cssClass}`}
      label={label}
      placeholder={helpText || explanationText}
    />
  )
}

export default ActivityInputArea
