import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Yta from './Yta'
import useFetchYtaObjects from './useFetchYtaObjects'

/*
  todo:
  - renovar a sessão do usuário antes de iniciar o teste
*/

const ActivityYta = ({ sourceDataContainerVariable }) => {
  const { interactionModel } = useSelector(({ interactions }) => interactions)

  const { ytaObjects, fetchYtaObjects } = useFetchYtaObjects()

  useEffect(() => {
    fetchYtaObjects({
      workflowUuid: interactionModel?.workflowUuid,
      sourceDataContainerVariable
    })
  }, [
    fetchYtaObjects,
    interactionModel?.workflowUuid,
    sourceDataContainerVariable
  ])

  return (
    <>
      <Yta {...{ ytaObjects }} />
    </>
  )
}

export default ActivityYta
