import { Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18n-lite'

import Error from './Error'
import { GridContainer } from 'style/grid'
import InteractionContainer from 'modules/interaction/containers/InteractionContainer'

const Router = () => (
  <Switch>
    <Route
      exact
      path='/:id_interaction_model'
      component={InteractionContainer}
    />
    <Route component={NoMatch} />
  </Switch>
)

const NoMatch = () => {
  const { t } = useTranslation()

  return (
    <GridContainer>
      <Error errorMessage={t('system.parameter-not-found')} />
    </GridContainer>
  )
}

export default Router
