import { FocusEventHandler, useCallback, useState } from 'react'

import ActivityInputString from './ActivityInputString'
import ActivityInputInteger from './ActivityInputInteger'
import ActivityInputSelect from './ActivityInputSelect'
import ActivityInputDate from './ActivityInputDate'
import ActivityInputFileS3 from './ActivityInputFileS3'
import ActivityInputTakePhoto from './ActivityInputTakePhoto'
import ActivityInputTakeVideo from './ActivityInputTakeVideo'
import ActivityInputAgreementTerm from './ActivityInputAgreementTerm'
import Autocomplete from './Autocomplete'

import '../../../../asset/css/form.css'
import SiteRenderer from '../SiteRenderer'
import ActivityInputDraw from './ActivityInputDraw'
import ActivityGroup from './ActivityGroup'
import { VALIDATION_STATUSES } from '../../../../utils/constants'
import useBlueprintsContext from '../../hooks/useBlueprintsContext'
import ActivityDocumentPhoto from './ActivityDocumentPhoto'
import ActivityInputArea from './ActivityInputArea'
import ActivityExplicitUniselection from './ActivityExplicitUniselection/ActivityExplicitUniselection'
import ActivityExplicitMultiselection from './ActivityExplicitMultiselection/ActivityExplicitMultiselection'
import ActivityInputGeolocation from './ActivityInputGeolocation'
import ActivityAutocompleteMultiselection from './ActivityAutocompleteMultiselection/ActivityAutocompleteMultiselection'
import ActivityAutocompleteUniselection from './ActivityAutocompleteUniselection/ActivityAutocompleteUniselection'
import ActivityYta from './ActivityYta'
import { ActivityModel } from 'types/shared'
import { ActivityInputProps } from './Activity.types'
import ActivityStandardMultiselection from './ActivityStandardMultiselection/ActivityStandardMultiselection'
import ActivityStandardUniselection from './ActivityStandardUniselection/ActivityStandardUniselection'

const ActivityInput = ({
  activityModelInput,
  inputClass,
  statusClass,
  currentStep,
  currentIndexActivityGroup,
  isFetchingBlueprints = false,
  handleChange
}: ActivityInputProps) => {
  if (activityModelInput.content_type === 'integer') {
    return (
      <ActivityInputInteger
        id={activityModelInput.uuid}
        cssClass={`${inputClass}`}
        value={activityModelInput.value || ''}
        required={activityModelInput.required}
        onChange={handleChange}
        label={activityModelInput.display_text.des}
        readOnly={activityModelInput?.informational}
        helpText={activityModelInput.help_text?.des}
        explanationText={activityModelInput.explanation_text?.des}
        formatMask={activityModelInput.format_mask}
      />
    )
  }

  if (activityModelInput.content_type === 'date') {
    return (
      <ActivityInputDate
        id={activityModelInput.uuid}
        statusClass={statusClass}
        value={activityModelInput?.value}
        format='date'
        required={activityModelInput.required}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
        helpText={activityModelInput.help_text?.des}
        explanationText={activityModelInput.explanation_text?.des}
        {...{ isFetchingBlueprints }}
        minimumYear={activityModelInput.minimum_year}
        maximumYear={activityModelInput.maximum_year}
      />
    )
  }

  if (activityModelInput.content_type === 'datetime') {
    return (
      <ActivityInputDate
        id={activityModelInput.uuid}
        statusClass={statusClass}
        cssClass={inputClass}
        value={activityModelInput?.value}
        format='date-time'
        required={activityModelInput.required}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
        helpText={activityModelInput.help_text?.des}
        explanationText={activityModelInput.explanation_text?.des}
        {...{ isFetchingBlueprints }}
        minimumYear={activityModelInput.minimum_year}
        maximumYear={activityModelInput.maximum_year}
      />
    )
  }

  if (activityModelInput.content_type === 'file') {
    return (
      <ActivityInputFileS3
        id={activityModelInput.uuid}
        cssClass={`${inputClass}`}
        inputValue={activityModelInput.value || ''}
        onChange={handleChange}
        required={activityModelInput.required}
        label={activityModelInput.display_text?.des}
        readOnly={activityModelInput.informational}
      />
    )
  }

  if (activityModelInput.content_type === 'take_photo') {
    return (
      <ActivityInputTakePhoto
        inputValue={activityModelInput.value || ''}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
      />
    )
  }

  if (activityModelInput.content_type === 'take_video') {
    return (
      <ActivityInputTakeVideo
        inputValue={activityModelInput.value || ''}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
      />
    )
  }

  if (activityModelInput.content_type === 'agreement_term') {
    return (
      <ActivityInputAgreementTerm
        onChange={handleChange}
        inputValue={activityModelInput.value || ''}
        terms={activityModelInput.display_text.des}
      />
    )
  }

  if (activityModelInput.content_type === 'draw') {
    return (
      <ActivityInputDraw
        inputValue={activityModelInput.value || ''}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
      />
    )
  }

  if (activityModelInput.content_type === 'activity_group') {
    return (
      <ActivityGroup
        currentActivityModel={activityModelInput}
        onChange={handleChange}
        label={activityModelInput.display_text.des}
        currentStep={currentStep}
      />
    )
  }

  if (activityModelInput.content_type === 'document_photo') {
    return (
      <ActivityDocumentPhoto
        inputValue={activityModelInput.value || ''}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
      />
    )
  }

  if (activityModelInput.content_type === 'textarea') {
    return (
      <ActivityInputArea
        id={activityModelInput.uuid}
        cssClass={`${inputClass}`}
        value={activityModelInput.value || ''}
        required={activityModelInput.required}
        onChange={handleChange}
        label={activityModelInput.display_text.des}
        readOnly={activityModelInput?.informational}
        helpText={activityModelInput.help_text?.des}
        explanationText={activityModelInput.explanation_text?.des}
      />
    )
  }

  if (activityModelInput.content_type === 'explicit_uniselection_objectclass') {
    return (
      <ActivityExplicitUniselection
        id={activityModelInput.uuid}
        value={activityModelInput.value || ''}
        options={activityModelInput.selection_options}
        filteraction={activityModelInput.information_request_filteraction}
        label={activityModelInput.display_text.des}
        direction={activityModelInput.direction || 'vertical'}
        optionLabelsPosition={
          activityModelInput.optionLabelsPosition || 'right'
        }
        required={activityModelInput.required}
        onChange={handleChange}
      />
    )
  }

  if (
    activityModelInput.content_type === 'explicit_multiselection_objectclass'
  ) {
    return (
      <ActivityExplicitMultiselection
        id={activityModelInput.uuid}
        value={activityModelInput.value || ''}
        options={activityModelInput.selection_options}
        filteraction={activityModelInput.information_request_filteraction}
        label={activityModelInput.display_text.des}
        direction={activityModelInput.direction || 'vertical'}
        optionLabelsPosition={
          activityModelInput.optionLabelsPosition || 'right'
        }
        required={activityModelInput.required}
        onChange={handleChange}
      />
    )
  }

  if (activityModelInput.content_type === 'geolocation') {
    return (
      <ActivityInputGeolocation
        id={activityModelInput.uuid}
        label={activityModelInput.display_text.des}
        value={
          typeof activityModelInput.value === 'string'
            ? activityModelInput.value
            : ''
        }
        required={activityModelInput.required}
        dependencies={activityModelInput.geolocation_dependencies}
        onChange={handleChange}
        currentIndexActivityGroup={currentIndexActivityGroup}
        fillGeolocationValues={activityModelInput.fill_geolocation_values}
      />
    )
  }

  /* Não está validado com ActivityGroup */
  if (activityModelInput.content_type === 'yta') {
    return (
      <ActivityYta
        sourceDataContainerVariable={
          activityModelInput.yta?.source_data_container_variable
        }
      />
    )
  }

  if (activityModelInput.content_type === 'autocomplete_multiselection') {
    return (
      <ActivityAutocompleteMultiselection
        id={activityModelInput.uuid}
        statusClass={statusClass}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        endpoint={activityModelInput.information_request_callback}
        value={activityModelInput.value || ''}
        onChange={handleChange}
        required={activityModelInput.required}
        label={activityModelInput.display_text.des}
        informational={activityModelInput?.informational}
        placeholder={activityModelInput?.explanation_text?.des}
        {...{ isFetchingBlueprints }}
      />
    )
  }

  if (activityModelInput.content_type === 'autocomplete_uniselection') {
    return (
      <ActivityAutocompleteUniselection
        id={activityModelInput.uuid}
        statusClass={statusClass}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        endpoint={activityModelInput.information_request_callback}
        value={activityModelInput.value || ''}
        onChange={handleChange}
        required={activityModelInput.required}
        label={activityModelInput.display_text.des}
        informational={activityModelInput?.informational}
        placeholder={activityModelInput?.explanation_text?.des}
        {...{ isFetchingBlueprints }}
      />
    )
  }

  if (activityModelInput.content_type === 'standard_uniselection') {
    return (
      <ActivityStandardUniselection
        id={activityModelInput.uuid}
        value={activityModelInput.value || ''}
        options={activityModelInput.selection_options}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        label={activityModelInput.display_text.des}
        required={activityModelInput.required}
        onChange={handleChange}
        statusClass={statusClass}
      />
    )
  }

  if (activityModelInput.content_type === 'standard_multiselection') {
    return (
      <ActivityStandardMultiselection
        id={activityModelInput.uuid}
        value={activityModelInput.value || ''}
        options={activityModelInput.selection_options}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        label={activityModelInput.display_text.des}
        required={activityModelInput.required}
        onChange={handleChange}
        statusClass={statusClass}
      />
    )
  }

  const isStringAsAutocomplete = Boolean(
    activityModelInput.information_request_callback ||
      activityModelInput.information_request_filteraction
  )

  if (isStringAsAutocomplete) {
    return (
      <Autocomplete
        id={activityModelInput.uuid}
        cssClass={`${inputClass}`}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        endpoint={activityModelInput.information_request_callback}
        value={activityModelInput.value || ''}
        onChange={handleChange}
        required={activityModelInput.required}
        label={activityModelInput.display_text.des}
        readOnly={activityModelInput?.informational}
        maxSug={null}
      />
    )
  }

  const isStringAsSelect = Boolean(
    activityModelInput.information_request_textexpression
  )

  if (isStringAsSelect) {
    return (
      <ActivityInputSelect
        id={activityModelInput.uuid}
        cssClass={`${inputClass}`}
        onChange={handleChange}
        options={activityModelInput.information_request_textexpression}
        inputValue={activityModelInput.value || ''}
        label={activityModelInput.display_text.des}
        readOnly={activityModelInput?.informational}
        required={activityModelInput.required}
        helpText={activityModelInput.help_text?.des}
        explanationText={activityModelInput.explanation_text?.des}
        {...{ isFetchingBlueprints }}
      />
    )
  }

  return (
    <ActivityInputString
      id={activityModelInput.uuid}
      statusClass={statusClass}
      value={activityModelInput?.value || ''}
      required={activityModelInput?.required}
      onChange={handleChange}
      label={activityModelInput?.display_text?.des}
      readOnly={activityModelInput?.informational}
      helpText={activityModelInput?.help_text?.des}
      explanationText={activityModelInput?.explanation_text?.des}
      formatMask={activityModelInput.format_mask}
      {...{ isFetchingBlueprints }}
    />
  )
}

export type ActivityProps = {
  activityModelInput: ActivityModel
  currentIndexActivityGroup?: number
  currentStep: number
  changeActivity: (
    uuid: string,
    value: { id: string; des: string } | string
  ) => void
}

export default function Activity({
  activityModelInput,
  changeActivity,
  currentIndexActivityGroup,
  currentStep
}: ActivityProps) {
  const inputClassByStatus = {
    [VALIDATION_STATUSES.VALID]: 'is-valid',
    [VALIDATION_STATUSES.INVALID]: 'is-invalid'
  }
  const inputClass =
    inputClassByStatus[
      activityModelInput.validationStatus as keyof typeof inputClassByStatus
    ] || ''

  const statusClassByValidation = {
    [VALIDATION_STATUSES.VALID]: 'valid',
    [VALIDATION_STATUSES.INVALID]: 'error'
  }

  const statusClass =
    statusClassByValidation[
      activityModelInput.validationStatus as keyof typeof statusClassByValidation
    ] || 'default'

  const informationRequestSitemodel =
    activityModelInput.information_request_sitemodel

  const { executeBlueprints, isFetchingBlueprints } = useBlueprintsContext()

  const isActivityGroup = activityModelInput.content_type === 'activity_group'

  const [previousValue, setPreviousValue] = useState<
    ActivityModel['value'] | false
  >(false)

  const handleBlur: FocusEventHandler = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) {
      return
    }
    if (previousValue !== activityModelInput.value && !isActivityGroup) {
      executeBlueprints()
    }
  }

  const handleFocus = () => {
    setPreviousValue(activityModelInput.value)
  }

  const handleChange = useCallback(
    (newValue: string | { id: string; des: string }) => {
      changeActivity(activityModelInput.uuid, newValue)
    },
    [activityModelInput.uuid, changeActivity]
  )

  return (
    <div
      onFocus={handleFocus}
      onBlur={handleBlur}
      data-testid='activity'
      className={`form-group ${
        activityModelInput.helperText ? 'has-validation' : ''
      }`}
    >
      {informationRequestSitemodel && (
        <SiteRenderer
          sitemodelUuid={informationRequestSitemodel}
          urlSiteRenderer={window.urlSiteRenderer || ''}
        />
      )}

      <ActivityInput
        {...{
          activityModelInput,
          handleChange,
          inputClass,
          currentIndexActivityGroup,
          currentStep,
          statusClass,
          isFetchingBlueprints
        }}
      />
    </div>
  )
}
