import jwtDecode from 'jwt-decode'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useQuery } from '../useQuery'
import apiAsync from '../../../utils/api-async'
import { useAuthentication } from 'authentication'

interface JwtHeader {
  typ?: string
  alg?: string
  kid?: string
}

const useToken = () => {
  const query = useQuery()

  const tokenFromUrl = query.get('token')
  const tokenFromLocalStorage = localStorage.getItem('token')
  const newToken = tokenFromUrl || tokenFromLocalStorage

  const history = useHistory()

  const { updateAuthData, token: tokenInState } = useAuthentication()

  useEffect(() => {
    if (!newToken || newToken === tokenInState) return

    try {
      const decodedHeader = jwtDecode<JwtHeader>(newToken, { header: true })
      if (decodedHeader?.typ !== 'JWT' || decodedHeader?.alg !== 'HS256') {
        throw new Error('Invalid token')
      }

      apiAsync.setAuthToken(newToken)

      const decoded = jwtDecode<{ email: string; expiration_date: number }>(
        newToken
      )

      updateAuthData({
        username: decoded.email,
        token: newToken,
        expirationDate: decoded.expiration_date
      })

      query.delete('token')
      history.replace({ search: query.toString() })
    } catch (error) {
      const err = error as { message: string }
      console.log(err.message)
    }
  }, [newToken, history, query, updateAuthData, tokenInState])

  return newToken
}

export default useToken
