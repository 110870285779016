import { useEffect, useMemo, useState } from 'react'
import { AutocompleteUniselection } from '@yes.technology/react-toolkit'
import useFetchSuggestions from '../../../hooks/useFetchSuggestions'
import { useSelector } from 'react-redux'
import debounce from 'lodash.debounce'

const getOptions = (options) =>
  options.map(({ des: value, uuid: id }) => ({ id, value }))

const ActivityAutocompleteUniselection = ({
  id,
  filteraction,
  filteractionParams,
  onChange,
  value: selectedOption,
  endpoint,
  statusClass,
  isFetchingBlueprints = false,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(selectedOption?.des || '')

  const { interactionModel } = useSelector(({ interactions }) => interactions)

  const { suggestions, isLoading, fetchSuggestions, resetSuggestions } =
    useFetchSuggestions({ id, filteraction, filteractionParams, endpoint })

  const options = getOptions(suggestions)

  const interactionModelItemGroupsState =
    interactionModel?.data?.interaction_model_item_groups

  const debouncedFetch = useMemo(
    () =>
      debounce((value) => {
        fetchSuggestions(value, interactionModelItemGroupsState)
      }, 350),
    [fetchSuggestions, interactionModelItemGroupsState]
  )

  const handleOnChange = (targetSelection) => {
    setInputValue(targetSelection)

    if (targetSelection === '') {
      resetSuggestions()
      onChange([])
      return
    }

    // todo: refactor this to use a uuid instead of a description
    const optionFound = options.find(
      (option) => option.value === targetSelection
    )

    if (!optionFound) {
      debouncedFetch(targetSelection)
      return
    }

    onChange({ id: optionFound.id, des: optionFound.value })
  }

  const updateInputValueFromProp = () => {
    if (!selectedOption?.des) {
      return
    }

    setInputValue(selectedOption.des)
  }

  useEffect(updateInputValueFromProp, [selectedOption?.des])

  return (
    <AutocompleteUniselection
      {...props}
      statusClass={isLoading ? 'processing' : statusClass}
      onChange={handleOnChange}
      isLoadingBlueprints={isFetchingBlueprints}
      value={inputValue}
      {...{ options }}
    />
  )
}

export default ActivityAutocompleteUniselection
