import {
  isActivityModelInvalid,
  getOnlyValidItemGroups
} from '../utils/validation'

const useNavigation = (itemGroups) => {
  const validatedInteraction =
    (getOnlyValidItemGroups(itemGroups) || [])
      .map((itemGroup) =>
        itemGroup.activity_models.filter(isActivityModelInvalid)
      )
      .flat().length === 0
  return {
    showDoubleArrowhead: validatedInteraction
  }
}

export default useNavigation
