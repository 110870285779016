import { Unifree, useMask } from '@yes.technology/react-toolkit'
import useRedundantState from '../../hooks/useRedundantState'

function ActivityInputString({
  id,
  label,
  readOnly,
  required,
  value,
  onChange,
  helpText,
  statusClass,
  explanationText,
  formatMask,
  isFetchingBlueprints = false
}) {
  const { applyMask } = useMask()
  const { redundantValue, setRedundantValue } = useRedundantState({ value })

  const handleDataFieldChange = (rawValue) => {
    const formattedValue = formatMask
      ? applyMask(rawValue, formatMask)
      : rawValue

    setRedundantValue(formattedValue)
    onChange(formattedValue)
  }

  return (
    <Unifree
      label={label}
      id={id}
      value={redundantValue}
      informational={readOnly}
      required={!!required}
      statusClass={readOnly ? 'default' : statusClass}
      onChange={handleDataFieldChange}
      placeholder={helpText || explanationText}
      aria-label={label}
      isLoadingBlueprints={isFetchingBlueprints}
    />
  )
}

export default ActivityInputString
