import { useEffect, useRef, useState } from 'react'
import debounce from 'lodash.debounce'

export default function useUpdateGeolocation({
  address,
  geocodeCallback,
  onChange,
  value
}) {
  const lastRequestedAddress = useRef(value && address)

  const [geocodeFailed, setGeocodeFailed] = useState(false)

  useEffect(() => {
    setGeocodeFailed(false)
  }, [address])

  useEffect(() => {
    if (
      !address ||
      (lastRequestedAddress.current === address && value) ||
      geocodeFailed
    ) {
      return
    }

    let cleanedUp = false
    const safeOnChange = (newValue) => !cleanedUp && onChange(newValue)

    const debouncedGeocodeRequest = debounce(async () => {
      lastRequestedAddress.current = address

      try {
        const { results } = await geocodeCallback({
          address
        })
        const { lat, lng } = results[0].geometry.location
        safeOnChange(`${lat()},${lng()}`)
      } catch (e) {
        console.error(e)
        setGeocodeFailed(true)
        safeOnChange('')
      }
    }, [350])
    debouncedGeocodeRequest()

    return () => {
      debouncedGeocodeRequest.cancel()
      cleanedUp = true
    }
  }, [onChange, address, geocodeCallback, value, geocodeFailed])
}
