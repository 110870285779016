import { useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import SignatureCanvas from 'react-signature-canvas'
import { useTranslation } from 'react-i18n-lite'

import '../../../../asset/css/form.css'
import useModal from '../../../shared/hooks/useModal'
import useUploadS3 from '../../hooks/useUploadS3'

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

function ActivityInputDraw({ inputValue: uploadedFileUrl, onChange, label }) {
  const { t } = useTranslation()
  const canvasRef = useRef({})
  const [signaturePreview, setSignaturePreview] = useState(null)
  const [isUploadable, setIsUploadable] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const { isOpenModal, openModal, closeModal } = useModal()

  const { currentStatus, setCurrentStatus, uploadFile } = useUploadS3()

  const onUploadStart = () => {
    setIsUploading(true)
  }

  const onUploadSuccess = (url, newDraw) => {
    onChange(url)
    setSignaturePreview(newDraw)
    closeModal()
    setIsUploading(false)
  }

  const onUploadError = (error) => {
    console.log(error)
    setIsUploading(false)
  }

  const handleSave = () => {
    const newDraw = canvasRef.current.getTrimmedCanvas().toDataURL('image/png')
    uploadFile({
      file: newDraw,
      fileType: 'base64',
      onStart: onUploadStart,
      onSuccess: (url) => onUploadSuccess(url, newDraw),
      onError: onUploadError
    })
  }

  const handleClearCanvas = () => {
    canvasRef.current.clear()
    setIsUploadable(false)
  }

  const handleDelete = () => {
    setSignaturePreview(null)
    onChange('')
    setCurrentStatus(t('activity-input-upload-s3.status.awaiting-upload'))
  }

  const allowUpload = () => {
    setIsUploadable(true)
  }

  useEffect(() => {
    setIsUploadable(false)
  }, [isOpenModal])

  return (
    <>
      {label && <label>{label}</label>}
      {signaturePreview && (
        <img
          src={signaturePreview}
          alt={t('activity-input-draw.signature')}
          className='border w-100 mb-1 d-block'
        />
      )}
      {!uploadedFileUrl && (
        <button className='btn btn-secondary d-block' onClick={openModal}>
          {t('activity-input-draw.sign')}
        </button>
      )}
      {uploadedFileUrl && (
        <>
          <div>
            {t('activity-input-upload-s3.saved-file-des')}:{' '}
            <strong>{uploadedFileUrl}</strong>{' '}
          </div>
          <button
            className='btn mt-2 btn-danger d-block'
            onClick={handleDelete}
          >
            {t('activity-input-upload-s3.btn-remove-file-des')}
          </button>
        </>
      )}
      <ReactModal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        style={modalStyles}
      >
        <div className={isUploading ? '' : 'text-danger'}>{currentStatus}</div>

        <SignatureCanvas
          onEnd={allowUpload}
          canvasProps={{
            className: 'border',
            style: { width: '80vw', minHeight: 200 },
            'data-testid': 'signature-canvas'
          }}
          ref={canvasRef}
        />

        <div style={{ display: 'flex', gap: 8 }}>
          <button
            className='btn btn-dark mr-2'
            onClick={handleSave}
            disabled={!isUploadable}
          >
            {t('activity-input-draw.upload')}
          </button>
          <button
            className='btn btn-danger'
            onClick={handleClearCanvas}
            disabled={!isUploadable}
          >
            {t('activity-input-draw.clear')}
          </button>
        </div>
      </ReactModal>
    </>
  )
}

export default ActivityInputDraw
