import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18n-lite'
import { DataField, Icon, defaultTheme } from '@yes.technology/react-toolkit'
import { v4 as uuidv4 } from 'uuid'

import api from '../../../../utils/api'

const DataFieldContainer = styled.div`
  position: relative;

  figure {
    position: absolute;
    right: 0.5rem;
    top: 50%;
  }
`

const DataFieldListContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
`

export const DataFieldList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 2px solid #abbed1;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 170px;
  overflow: auto;
`

export const DataFieldListItem = styled.li`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ spacingClass = 'medium' }) =>
    defaultTheme.dataField[spacingClass]?.fontSize}px;
  color: #303032;
  padding: ${({ spacingClass = 'medium' }) =>
    `${defaultTheme.dataField[spacingClass]?.borderSizeY}px ${defaultTheme.dataField[spacingClass]?.borderSizeX}px`};
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  ${({ isSelected }) =>
    isSelected
      ? `
    background: ${defaultTheme.colors._CCE1FF};
  `
      : ''}

  &:hover {
    background: ${defaultTheme.colors._CCE1FF};
  }
`

function Autocomplete({
  id,
  endpoint,
  value,
  onChange,
  cssClass,
  required,
  maxSug,
  filteraction,
  filteractionParams,
  label,
  readOnly
}) {
  const { t } = useTranslation()

  const currentRequestId = useRef(0)
  const suggestionsRef = useRef()
  const inputRef = useRef(value)
  const isSubscribed = useRef(true)
  const listRefs = useRef([])

  const listItemHeight = 41
  const listHeight = listItemHeight * 5

  const [inputValue, setInputValue] = useState(value.des || '')

  useEffect(() => {
    value && setInputValue(value.des)
  }, [value])

  const [state, setState] = useState({
    suggestions: [],
    selectedSuggestion: value || '',
    activeSuggestion: -1,
    loadingSuggestions: false,
    showSuggestions: false,
    error: null
  })

  // Busca interactionModel que está salva na store do redux
  const { interactionModel } = useSelector(({ interactions }) => interactions)

  useEffect(() => {
    const handleMousedown = (event) => {
      const wereSuggestionsClicked = suggestionsRef.current?.contains(
        event.target
      )

      if (!wereSuggestionsClicked && isSubscribed.current === true) {
        setState({
          ...state,
          suggestions: [],
          activeSuggestion: -1,
          showSuggestions: false,
          loadingSuggestions: false
        })
      }
    }

    document.addEventListener('mousedown', handleMousedown)

    isSubscribed.current = true

    return () => {
      document.removeEventListener('mousedown', handleMousedown)
      isSubscribed.current = false
    }
  }, [state])

  const scrollList = (nextActiveSuggestion, direction) => {
    const listItemAbsolutePos =
      listRefs.current[nextActiveSuggestion].getBoundingClientRect().top
    const divSuggestionsPos = suggestionsRef.current.getBoundingClientRect().top
    const listItemRelativePos = listItemAbsolutePos - divSuggestionsPos

    const shouldScroll =
      (direction === 'up' && listItemRelativePos <= 0) ||
      (direction === 'down' && listItemRelativePos >= listHeight)

    const scrollPos =
      listRefs.current[nextActiveSuggestion].getBoundingClientRect().height *
      nextActiveSuggestion

    if (shouldScroll) {
      suggestionsRef.current.scrollTo(0, scrollPos)
    }
  }

  const handleOnKeyDown = (e) => {
    const { activeSuggestion, suggestions } = state
    // User pressed the enter key
    if (e.keyCode === 13 && suggestions[activeSuggestion]) {
      inputRef.current.focus()
      // this.setSelectedSuggestionsAfterEnter(suggestions[activeSuggestion])

      setSelectedSuggestion(suggestions[activeSuggestion])
    } else if (e.keyCode === 38) {
      // User pressed the up arrow

      if (activeSuggestion === 0) {
        return
      }

      const nextActiveSuggestion = activeSuggestion - 1

      // Scrolling suggestion list
      scrollList(nextActiveSuggestion, 'up')
      // End of scrolling suggestion list

      setState({
        ...state,
        activeSuggestion: nextActiveSuggestion,
        showSuggestions: true,
        suggestions: suggestions
      })
    } else if (e.keyCode === 40) {
      // User pressed the down arrow

      if (activeSuggestion === suggestions.length - 1) {
        return
      }

      const nextActiveSuggestion = activeSuggestion + 1

      // Scrolling suggestion list
      scrollList(nextActiveSuggestion, 'down')
      // End of scrolling suggestion list

      setState({
        ...state,
        activeSuggestion: nextActiveSuggestion,
        showSuggestions: true,
        suggestions: suggestions
      })
    } else if (e.keyCode === 27) {
      setInputValue('')
      // User pressed the esc key
      setState({
        ...state,
        suggestions: [],
        activeSuggestion: -1,
        showSuggestions: false
      })
    }
  }

  const setSelectedSuggestion = (suggestion) => {
    setState({
      ...state,
      showSuggestions: false
    })

    onChange(suggestion)
  }

  const fetchSuggestions = useCallback(
    (value, interactionModelItemGroupsState, currentId) => {
      if (isSubscribed.current === true) {
        api
          .getAutocomplete(
            value,
            endpoint,
            false,
            filteraction,
            filteractionParams,
            interactionModelItemGroupsState,
            id
          )
          .then((response) => {
            if (currentId !== currentRequestId.current) {
              return
            }

            if (response.success === true) {
              // Compatibilidade entre /objects/_filter e callback
              let results = response.data
              if (response.data.objects) {
                results = response.data.objects
                results = results.map((i) => {
                  i.id = i.uuid
                  return i
                })
              }

              if (!Array.isArray(results)) {
                results = []
              }

              const subsetSugestions =
                maxSug && maxSug > 0 ? results.slice(0, maxSug) : results

              if (
                subsetSugestions.some(
                  (s) => typeof s !== 'object' || !s.id || !s.des
                )
              ) {
                throw new Error({ message: t('generic-error') })
              }

              setState((oldState) => ({
                ...oldState,
                suggestions: subsetSugestions,
                loadingSuggestions: false,
                showSuggestions: true
              }))
            } else {
              throw new Error({ message: response.message })
            }
          })
          .catch((e) => {
            setState((oldState) => ({
              ...oldState,
              loadingSuggestions: false,
              showSuggestions: true,
              error: e?.message
            }))
          })

        isSubscribed.current = false
      }
    },
    [endpoint, filteraction, filteractionParams, maxSug, t, id]
  )

  const fetchSuggestionsByInput = useCallback(
    (userInput, interactionModelItemGroupsState) => {
      const str = userInput.replace(/\s/g, '')

      if (isSubscribed.current && str.length > 0) {
        const currentId = uuidv4()

        currentRequestId.current = currentId

        setState((oldState) => ({
          ...oldState,
          loadingSuggestions: true,
          showSuggestions: true
        }))
        fetchSuggestions(userInput, interactionModelItemGroupsState, currentId)
      }
    },
    [fetchSuggestions]
  )

  const debouncedFetchSuggestions = useMemo(
    () =>
      debounce((nextValue, interactionModelItemGroupsState) => {
        fetchSuggestionsByInput(nextValue, interactionModelItemGroupsState)
      }, 350),
    [fetchSuggestionsByInput]
  )

  const handleOnChange = (value) => {
    const interactionModelItemGroupsState =
      interactionModel?.data?.interaction_model_item_groups

    setInputValue(value)

    onChange('')

    if (value) {
      debouncedFetchSuggestions(value, interactionModelItemGroupsState)
    }
  }

  const {
    suggestions,
    loadingSuggestions,
    showSuggestions,
    activeSuggestion,
    error
  } = state

  const preventFocusLoss = (event) => {
    event.preventDefault()
    inputRef.current.focus()
  }

  return (
    <div className='autocomplete-container'>
      <DataFieldContainer>
        <DataField
          id={id}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          autoComplete='off'
          required={required}
          value={inputValue}
          ref={inputRef}
          className={`${readOnly ? '' : cssClass}`}
          label={label}
          informational={readOnly}
        />
        <Icon
          iconName={showSuggestions ? 'Close' : 'DropdownArrowLow'}
          icSize='medium'
          icColor='cyanBlueInputBorder'
        />
      </DataFieldContainer>

      {showSuggestions && (
        <DataFieldListContainer
          ref={suggestionsRef}
          tabIndex='0'
          className='autocomplete-suggestions'
        >
          {!loadingSuggestions && !error && (
            <DataFieldList onKeyDown={handleOnKeyDown}>
              {suggestions?.length === 0 && (
                <DataFieldListItem className='list-group-item'>
                  No results found
                </DataFieldListItem>
              )}
              {suggestions?.length > 0 &&
                suggestions.map(({ des, id }, index) => {
                  return (
                    <DataFieldListItem
                      ref={(el) => (listRefs.current[index] = el)}
                      key={id}
                      data-id={id}
                      className={
                        index === activeSuggestion
                          ? 'list-group-item-secondary'
                          : ''
                      }
                      onMouseDown={preventFocusLoss}
                      onClick={() => {
                        setSelectedSuggestion({ id, des })
                      }}
                    >
                      {des}
                    </DataFieldListItem>
                  )
                })}
            </DataFieldList>
          )}

          {!loadingSuggestions && error && error !== '' && (
            <DataFieldList onKeyDown={handleOnKeyDown} className='list-group'>
              <DataFieldListItem className='list-group-item list-group-item-danger'>
                {error}
              </DataFieldListItem>
            </DataFieldList>
          )}

          {loadingSuggestions && (
            <DataFieldList
              onKeyDown={handleOnKeyDown}
              className='list-group'
              data-testid='autocomplete-loading'
            >
              <DataFieldListItem className='list-group-item list-group-item-action'>
                {t('hello-yes.loading')}
              </DataFieldListItem>
            </DataFieldList>
          )}
        </DataFieldListContainer>
      )}
    </div>
  )
}
export default Autocomplete
