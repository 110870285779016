import { locales as pkgLocales } from '@yes.technology/react-toolkit'
import mergeDeepRight from 'ramda/src/mergeDeepRight'

import enUS from './en-US.json'
import ptBR from './pt-BR.json'
import deDE from './de-DE.json'

export const defaultLanguage = 'en-US'

const locales = {
  'en-US': mergeDeepRight(enUS, pkgLocales['en-US']),
  'pt-BR': mergeDeepRight(ptBR, pkgLocales['pt-BR']),
  'de-DE': mergeDeepRight(deDE, pkgLocales['de-DE'])
}

export default locales
