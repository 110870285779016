import api from './api'

const UUID_OBJECTCLASS_SITUATIONACTION = 'UUID_Objectclass_Situationaction'
const UUID_OBJECTCLASS_STATUS = 'UUID_OBJECTCLASS_STATUS'
const UUID_OBJECTCLASS_STATUSACTION = 'UUID_OBJECTCLASS_STATUSACTION'
const UUID_OBJECTCLASS_SITUATION = 'UUID_OBJECTCLASS_SITUATION'

const body = ({ objectclass, $and }) => ({
  query: {
    $and: [
      {
        'situation.des': {
          $eq: '40'
        },
        ...$and
      }
    ]
  },
  objectclass: objectclass,
  orderby: [],
  pagination: {
    offset: 0,
    limit: 100
  },
  visualization: ''
})

const apiSituationAction = ({ $and, showLoading }) => {
  return api.postObjectsFilter(
    body({
      objectclass: UUID_OBJECTCLASS_SITUATIONACTION,
      $and
    }),
    showLoading
  )
}

const apiStatusAction = ({ $and, showLoading }) => {
  return api.postObjectsFilter(
    body({
      objectclass: UUID_OBJECTCLASS_STATUSACTION,
      $and
    }),
    showLoading
  )
}

const apiStatus = ({ $and, showLoading }) => {
  return api
    .postObjectsFilter(
      body({
        objectclass: UUID_OBJECTCLASS_STATUS,
        $and
      }),
      showLoading
    )
    .catch((error) => console.log(error))
}

const apiSituation = ({ $and, showLoading }) => {
  return api.postObjectsFilter(
    body({
      objectclass: UUID_OBJECTCLASS_SITUATION,
      $and
    }),
    showLoading
  )
}

export { apiSituationAction, apiStatusAction, apiStatus, apiSituation }
