import { isMobile } from 'react-device-detect'

import YtaContainer from './YtaContainer'

import './assets/css/yta.css'

const Yta = ({ ytaObjects = {} }) => {
  if (!isMobile) {
    return <div>This content is available only on mobile.</div>
  }

  return <YtaContainer {...ytaObjects} />
}

export default Yta
