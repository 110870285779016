import { Button } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import { useReviewSaveButtonContext } from './ReviewSaveButtonContext'

export type ReviewSaveButtonProps = {
  totalSteps: number
  currentStep: number
  changeStep: (step: number) => void
}

export default function ReviewSaveButton({
  totalSteps,
  currentStep,
  changeStep
}: ReviewSaveButtonProps) {
  const { t } = useTranslation()
  const { stickySaveVisible } = useReviewSaveButtonContext()

  if (!stickySaveVisible || totalSteps !== currentStep) {
    return null
  }

  const onSave = () => changeStep(totalSteps + 1)

  return (
    <Button
      className='rounded-0'
      onClick={onSave}
      impactClass='situationChange'
    >
      {t('step.review.save-and-activate')}
    </Button>
  )
}
