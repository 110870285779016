import { ExplicitSelectionButton } from '@yes.technology/react-toolkit'
import useFetchSuggestions from '../../../hooks/useFetchSuggestions'
import { useEffect } from 'react'
import { ActivityModel } from 'types/shared'
import { ActivityInputProps } from '../Activity.types'

export type ActivityExplicitMultiselectionProps = {
  id: string
  label: string
  value: ActivityModel['value']
  filteraction?: string
  onChange: ActivityInputProps['handleChange']
} & Pick<
  React.ComponentProps<typeof ExplicitSelectionButton>,
  'id' | 'label' | 'options' | 'optionLabelsPosition' | 'direction' | 'required'
>

const ActivityExplicitMultiselection = ({
  id,
  label,
  value,
  filteraction,
  options = [],
  onChange,
  direction,
  optionLabelsPosition,
  required
}: ActivityExplicitMultiselectionProps) => {
  const selectedOptions = {
    des: (typeof value === 'object' && value?.des?.split(', ')) || [],
    id: (typeof value === 'object' && value?.id?.split(', ')) || []
  }
  const { suggestions, fetchSuggestions } = useFetchSuggestions({
    id,
    filteraction,
    showLoading: true
  })

  useEffect(() => {
    if (!filteraction) {
      return
    }

    fetchSuggestions('.*')
  }, [fetchSuggestions, filteraction])
  const finalOptions = options.length ? options : suggestions

  const getCheckedOptions = () => {
    let selectedOption: (typeof options)[0] | undefined

    return selectedOptions.des.reduce(
      (accumulator: Record<string, true>, current) => {
        selectedOption = finalOptions.filter(
          (option) => option.des === current
        )[0]

        if (selectedOption) {
          accumulator[selectedOption.uuid] = true
        }

        return accumulator
      },
      {}
    )
  }

  const handleChange = ({ des, uuid }: { des: string; uuid: string }) => {
    const index = selectedOptions.id.indexOf(uuid)

    if (index === -1) {
      selectedOptions.des.push(des)
      selectedOptions.id.push(uuid)
    } else {
      selectedOptions.des.splice(index, 1)
      selectedOptions.id.splice(index, 1)
    }

    onChange({
      id: selectedOptions.id.join(', '),
      des: selectedOptions.des.join(', ')
    })
  }

  return (
    <ExplicitSelectionButton
      heightClass='medium'
      label={label}
      selectionType='multiselection'
      options={finalOptions}
      direction={direction}
      optionLabelsPosition={optionLabelsPosition}
      checkedOption={getCheckedOptions()}
      id={id}
      required={required}
      onChange={handleChange}
    />
  )
}

export default ActivityExplicitMultiselection
