import { useEffect } from 'react'
import { useResizeDetector } from 'react-resize-detector'

const ResizeDetector = ({ children }) => {
  const { height, ref } = useResizeDetector()

  useEffect(() => {
    window.parent.postMessage(
      {
        height
      },
      '*'
    )
  }, [height])

  return (
    <div id='resize-detector-wrapper' ref={ref}>
      {children}
    </div>
  )
}

export default ResizeDetector
