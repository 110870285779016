import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import geolocation from '../../../utils/geolocation'
import { interactionModelOperations } from '../redux'

const useGeolocation = (geolocationState) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!geolocationState) {
      const isAvailable = geolocation.isAvailable()
      if (isAvailable) {
        geolocation
          .getCurrentPosition()
          .then((position) => {
            const {
              coords: { latitude },
              coords: { longitude }
            } = position
            dispatch(
              interactionModelOperations.setGeolocation({
                latitude,
                longitude
              })
            )
          })
          .catch((error) => {
            console.log(error.message)
          })
      }
    }
  }, [dispatch, geolocationState])
  return null
}

export default useGeolocation
