import apiAsync from './api-async'
import {
  resolveQuery,
  fetchUUIDDes,
  fetchFilteraction
} from '../modules/imported/FilteractionResolver'
import { Response } from 'cross-fetch'
import { Dispatch } from 'redux'
import {
  FilteractionParams,
  GenericAPIResponse,
  ReplaceFilteractionParamsParams
} from './apiTypes'
import { ActivityModel, InteractionModelItemGroup } from 'types/shared'
import { normalizeFilterRegex } from '@yes.technology/react-toolkit'

class Api {
  private dispatch!: Dispatch

  setDispatch(dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  postLogin(obj: unknown, showLoading: boolean) {
    return apiAsync.requestSync({
      endpoint: 'login',
      method: 'POST',
      obj,
      showLoading
    })
  }

  postInputValidation(obj: unknown, endpoint: string, showLoading: boolean) {
    return apiAsync.requestSync({
      endpoint,
      method: 'POST',
      obj,
      showLoading,
      customEndpoint: true
    })
  }

  getAutocomplete(
    value: string,
    endpoint: string,
    showLoading: boolean,
    filteraction?: string,
    filteractionParams?: FilteractionParams[],
    interactionModelItemGroupsState?: InteractionModelItemGroup[],
    currentActivityUuid?: string
  ) {
    if (filteraction) {
      return this.getAutocompleteWithFilteraction(
        value,
        filteraction,
        showLoading,
        filteractionParams,
        interactionModelItemGroupsState,
        currentActivityUuid
      )
    }
    return apiAsync.requestSync({
      endpoint: `${endpoint}?s=${encodeURIComponent(value)}`,
      method: 'GET',
      showLoading,
      customEndpoint: true
    })
  }

  replaceFilteractionParams({
    filteractionParams,
    activityModels,
    filteraction,
    currentActivityUuid
  }: ReplaceFilteractionParamsParams) {
    filteractionParams.forEach((param) => {
      let object: ActivityModel | undefined

      activityModels.forEach((activities) => {
        if (
          activities.content_type === 'activity_group' &&
          activities?.activity_models
        ) {
          let currentGroup = 0
          activities.activity_models.forEach((item, index) => {
            item.forEach((activity) => {
              if (activity.uuid === currentActivityUuid) {
                currentGroup = index
              }
            })
          })

          activities.activity_models[currentGroup].forEach((activityGroup) => {
            if (activityGroup.originalUuid === param.input_value_activity_id) {
              object = activityGroup
            }
          })
        }

        if (activities.uuid === param.input_value_activity_id) {
          object = activities
        }
      })

      if (object && object.value) {
        const value =
          typeof object.value === 'object'
            ? object.value.id || object.value.des
            : object.value
        filteraction.query = filteraction.query.replace(
          param.query_param_name,
          value
        )
      }
    })
  }

  async getAutocompleteWithFilteraction(
    value: string,
    filteractionId: string,
    showLoading: boolean,
    filteractionParams?: FilteractionParams[],
    interactionModelItemGroupsState?: InteractionModelItemGroup[],
    currentActivityUuid?: string
  ) {
    const onProgressCallback = () => undefined
    const filteraction = await fetchFilteraction(filteractionId, showLoading)

    if (filteractionParams && interactionModelItemGroupsState?.length) {
      // percorre todos os steps para encontrar activities com o mesmo UUID dos parâmetros da filteraction e executar replace das strings
      interactionModelItemGroupsState.forEach((interactionModel) => {
        // todo: talvez no futuro aqui entre uma validação para activity_group
        this.replaceFilteractionParams({
          filteractionParams,
          activityModels: interactionModel.activity_models,
          filteraction,
          currentActivityUuid: currentActivityUuid || ''
        })
        return null
      })
    }

    const query = filteraction?.query ? JSON.parse(filteraction?.query) : {}
    const objectclassId = filteraction?.filter_action_objectclass?.[0]?.uuid

    const filteractionResolvedQuery = await resolveQuery(
      query,
      onProgressCallback,
      [filteractionId],
      showLoading
    )

    // Agora juntamos os IDs retornados pela filteraction com
    // a descrição digitada no autocomplete
    const autocompleteQuery = {
      $and: [
        filteractionResolvedQuery,
        {
          'des.des': { $regex: normalizeFilterRegex(value) }
        }
      ]
    }
    const results = fetchUUIDDes(
      objectclassId,
      autocompleteQuery,
      onProgressCallback,
      showLoading
    )
    return results
  }

  postObjectsFilter(obj: unknown, showLoading: boolean) {
    return apiAsync
      .requestSync({
        endpoint: 'objects_filter',
        method: 'POST',
        obj,
        showLoading,
        throwError: true
      })
      .catch((error: unknown) => {
        if (error instanceof Response) {
          this.dispatch({ type: 'api/ERROR', data: { status: error.status } })
        }
      })
  }

  postReloadToken(obj: unknown, showLoading?: boolean) {
    return apiAsync.requestSync({
      endpoint: 'reload-token',
      method: 'POST',
      obj,
      showLoading
    })
  }

  postSaveInteraction(obj: unknown, showLoading: boolean) {
    return apiAsync
      .requestSync({
        endpoint: 'interactions',
        method: 'POST',
        obj,
        showLoading,
        throwError: true
      })
      .catch((error: unknown) => {
        if (error instanceof Response) {
          this.dispatch({ type: 'api/ERROR', data: { status: error.status } })
          throw error
        }
      })
  }

  getInteractionModels(idInteractionModel: string, showLoading: boolean) {
    return apiAsync
      .requestSync({
        endpoint: 'interaction-models',
        method: 'GET',
        path: '/' + idInteractionModel,
        showLoading
      })
      .then((response: GenericAPIResponse) => {
        if (response && response.success && response.data) {
          return response
        }

        throw new Error(response?.message)
      })
      .catch((e: unknown) => {
        throw new Error(e instanceof Error ? e.message : 'Erro desconhecido')
      })
  }

  patchObjects(obj: unknown, uuid: string, showLoading: boolean) {
    return apiAsync
      .requestSync({
        endpoint: 'objects',
        method: 'PATCH',
        path: '/' + uuid,
        obj,
        showLoading,
        throwError: true
      })
      .catch((error: unknown) => {
        if (error instanceof Response) {
          this.dispatch({ type: 'api/ERROR', data: { status: error.status } })
        }
      })
  }

  postObjects(obj: unknown, showLoading: boolean) {
    return apiAsync
      .requestSync({
        endpoint: 'objects',
        method: 'POST',
        obj,
        showLoading,
        throwError: true
      })
      .catch((error: unknown) => {
        if (error instanceof Response) {
          this.dispatch({ type: 'api/ERROR', data: { status: error.status } })
        }
      })
  }
}

export default new Api()
