import { useDispatch } from 'react-redux'
import { interactionModelOperations } from '../redux'

const KEYS_GEOCODE_FROM_DEPENDENCY = {
  number: 'street_number',
  street: 'route',
  neighborhood: 'sublocality_level_1',
  city: 'administrative_area_level_2',
  state: 'administrative_area_level_1',
  country: 'country',
  zip_code: 'postal_code'
}

export const useCalcUserAddressFromLatLong = (
  geolocation_dependencies,
  coord
) => {
  const dispatch = useDispatch()

  const processUserAddressFromLatLong = () => {
    const latLng = new google.maps.LatLng(coord.latitude, coord.longitude)
    const { geocode } = new window.google.maps.Geocoder()

    geocode({ latLng }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        return
      }

      const activities = []
      Object.keys(geolocation_dependencies).forEach((dependencyKey) => {
        const geocodeKey = KEYS_GEOCODE_FROM_DEPENDENCY[dependencyKey]

        const geocodeObject = results[0]?.address_components?.find((obj) =>
          obj.types?.includes(geocodeKey)
        )
        const des = geocodeObject?.long_name

        if (des) {
          activities.push({
            uuid_activity_model: geolocation_dependencies[dependencyKey],
            des
          })
        }
      })

      dispatch(interactionModelOperations.updateActivities(activities))
    })
  }

  return { processUserAddressFromLatLong }
}
