import { useEffect, useState } from 'react'

const useIframe = ({ origin, src, title }) => {
  const [height, setHeight] = useState('100vh')
  const [iframeAttributes, setIframeAttributes] = useState()

  const receiveMessage = (event) => {
    if (event.origin !== origin.replace(/\/$/g, '')) return false

    const { height } = event.data

    if (height) {
      setHeight(`${Math.ceil(Number(height) + 50)}`)
    }
  }

  useEffect(() => {
    setIframeAttributes({
      src,
      height,
      title
    })
  }, [height, src, title])

  return {
    iframeAttributes,
    receiveMessage
  }
}

export default useIframe
