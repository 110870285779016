import { ActivityModel } from 'types/shared'
import ActivityDocumentPhoto from './ActivityDocumentPhoto'
import ActivityValue from './ActivityValue'

interface ActivityProps {
  activityModels: ActivityModel[]
}

const Activity = ({ activityModels }: ActivityProps) => {
  return (
    <>
      {activityModels?.map((activity) => {
        const isActivityGroup = activity.content_type === 'activity_group'
        return (
          <div key={activity.uuid}>
            {activity?.value && (
              <>
                {activity.content_type === 'document_photo' &&
                activity?.value ? (
                  <ActivityDocumentPhoto values={activity.value as string} />
                ) : (
                  <ActivityValue
                    uuid={activity.uuid}
                    label={activity.display_text.des}
                    value={activity.value}
                    contentType={activity.content_type}
                    required={activity.required}
                  />
                )}
              </>
            )}

            {isActivityGroup &&
              activity.activity_models &&
              activity.activity_models.map((activityGroup, index) => {
                return (
                  <div key={index} className='mb-2'>
                    <div className='card-body'>
                      <h2>{activity.display_text.des}</h2>
                      <Activity activityModels={activityGroup} />
                    </div>
                  </div>
                )
              })}
          </div>
        )
      })}
    </>
  )
}

export default Activity
