import { useEffect, useState } from 'react'

const Iframe = (props) => {
  const [attributes, setAttributes] = useState()

  useEffect(() => {
    setAttributes(props)
  }, [props])

  const style = {
    border: 'none',
    display: 'block',
    width: '100%',
    padding: 0,
    margin: 0
  }

  return (
    <iframe
      {...attributes}
      height={props?.height}
      style={style}
      scrolling='no'
    />
  )
}

export default Iframe
