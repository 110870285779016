import { useTranslation } from 'react-i18n-lite'

import { useQuery } from '../useQuery'

const useHandleLanguage = () => {
  const { setLanguage } = useTranslation()
  const query = useQuery()
  const lang = query.get('lang')

  if (lang) {
    setLanguage(lang)
  }
}

export default useHandleLanguage
