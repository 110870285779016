/*
NOTE: This feature is available only in secure contexts (HTTPS) or localhost

How to use:
useEffect(() => {
  const isAvailable = geolocation.isAvailable()
  if (isAvailable) {
    geolocation.getCurrentPosition()
      .then((position) => {
        console.log(position)
      }).catch(error => {
        console.log(error)
      })
  }
})

*/
class Geolocation {
  isAvailable() {
    return 'geolocation' in navigator
  }

  /*
    options:
    https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
  */
  getCurrentPosition(options) {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
    )
  }
}

export default new Geolocation()
