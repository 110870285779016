import PropTypes from 'prop-types'
import { useEffect } from 'react'

import useIframe from '../../../shared/hooks/useIframe'
import Iframe from '../../../shared/Iframe'
import { useAuthentication } from 'authentication'
import { useTranslation } from 'react-i18n-lite'

const SiteRenderer = ({ sitemodelUuid, urlSiteRenderer }) => {
  const { token } = useAuthentication()

  const { language } = useTranslation()

  const queryString = `token=${token}&lang=${language}`
  const iframeSrc = `${urlSiteRenderer}/${sitemodelUuid}?${queryString}`

  const { iframeAttributes, receiveMessage } = useIframe({
    origin: urlSiteRenderer,
    src: iframeSrc,
    title: 'SiteRenderer'
  })

  useEffect(() => {
    window.addEventListener('message', receiveMessage)
    return () => window.removeEventListener('message', receiveMessage)
  })

  return iframeAttributes ? <Iframe {...iframeAttributes} /> : null
}

SiteRenderer.propTypes = {
  sitemodelUuid: PropTypes.string.isRequired,
  urlSiteRenderer: PropTypes.string.isRequired
}

export default SiteRenderer
