import PropTypes from 'prop-types'

const Progress = ({ valueNow, valueMin, valueMax, heightProgress, label }) => {
  return (
    <div className='progress mb-4' style={{ height: `${heightProgress}px` }}>
      <div
        className='progress-bar'
        role='progressbar'
        style={{ width: `${valueNow}%`, overflow: 'visible' }}
        aria-valuenow={valueNow}
        aria-valuemin={valueMin}
        aria-valuemax={valueMax}
        data-testid='progress-bar'
      >
        {label}
      </div>
    </div>
  )
}

Progress.defaultProps = {
  valueNow: 0,
  valueMin: 0,
  valueMax: 100,
  heightProgress: 16,
  label: ''
}

Progress.propTypes = {
  valueNow: PropTypes.number,
  valueMin: PropTypes.number,
  valueMax: PropTypes.number,
  height: PropTypes.number,
  label: PropTypes.string
}

export default Progress
