import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18n-lite'

export default function NetworkDetector() {
  const [isDisconnected, setIsDisconnected] = useState(false)

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange)
    window.addEventListener('offline', handleConnectionChange)

    // cleanup when the component unmounts
    return () => {
      window.removeEventListener('online', handleConnectionChange)
      window.removeEventListener('offline', handleConnectionChange)
    }
  })

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline'
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors'
        })
          .then(() => {
            setIsDisconnected(false)
            return clearInterval(webPing)
          })
          .catch(() => setIsDisconnected(true))
      }, 2000)
      return
    }
    return setIsDisconnected(true)
  }

  const { t } = useTranslation()

  return (
    <div>
      {isDisconnected && (
        <div className='alert alert-warning rounded-0 mb-0 text-center'>
          {t('system.internet-connection-lost')}
        </div>
      )}
    </div>
  )
}
