import { ExplicitSelectionButton } from '@yes.technology/react-toolkit'
import useFetchSuggestions from '../../../hooks/useFetchSuggestions'
import { useEffect } from 'react'
import { ActivityModel } from 'types/shared'
import { ActivityInputProps } from '../Activity.types'

export type ActivityExplicitUniselectionProps = {
  id: string
  label: string
  value: ActivityModel['value']
  filteraction?: string
  onChange: ActivityInputProps['handleChange']
} & Pick<
  React.ComponentProps<typeof ExplicitSelectionButton>,
  'id' | 'label' | 'options' | 'optionLabelsPosition' | 'direction' | 'required'
>

const ActivityExplicitUniselection = ({
  id,
  label,
  value,
  options = [],
  onChange,
  direction,
  optionLabelsPosition,
  filteraction,
  required
}: ActivityExplicitUniselectionProps) => {
  const { suggestions, fetchSuggestions } = useFetchSuggestions({
    id,
    filteraction,
    showLoading: true
  })

  useEffect(() => {
    if (!filteraction) {
      return
    }

    fetchSuggestions('.*')
  }, [fetchSuggestions, filteraction])

  const finalOptions = options.length ? options : suggestions
  const checkedOption = options.find(
    (option) => typeof value === 'object' && option.uuid === value?.id
  )

  const handleChange = ({ des, uuid }: { des: string; uuid: string }) => {
    const checkedLabel = checkedOption?.des === des ? '' : des

    checkedLabel && onChange({ id: uuid, des })
  }

  return (
    <ExplicitSelectionButton
      heightClass='medium'
      label={label}
      selectionType='uniselection'
      options={finalOptions}
      direction={direction}
      optionLabelsPosition={optionLabelsPosition}
      checkedOption={checkedOption?.uuid || ''}
      id={id}
      required={required}
      onChange={handleChange}
    />
  )
}

export default ActivityExplicitUniselection
