import { DataField, useMask } from '@yes.technology/react-toolkit'

function ActivityInputInteger({
  id,
  value,
  readOnly,
  required,
  onChange,
  cssClass,
  label,
  helpText,
  explanationText,
  formatMask
}) {
  const removeNonIntegerCharacters = (value) => {
    const regex = /[^0-9]+/g
    const replacedValue = value.replace(regex, '')

    return replacedValue
  }

  const { applyMask } = useMask()

  const handleValue = (value) => {
    const integerValue = removeNonIntegerCharacters(value)
    const formattedValue = formatMask
      ? applyMask(integerValue, formatMask)
      : integerValue

    onChange(formattedValue)
  }

  return (
    <DataField
      id={id}
      className={`${readOnly ? '' : cssClass}`}
      required={required}
      informational={readOnly}
      value={value}
      onChange={handleValue}
      label={label}
      placeholder={helpText || explanationText}
      aria-label={label}
    />
  )
}

export default ActivityInputInteger
