import { useEffect, useState } from 'react'

import Screen from './Screen'
import useTimeScreen from './useTimeScreen'

/*
  WIP:
  YtaContainer:
    Exibição das screens
      - configurações: prática, oficial, linhas, colunas, tempo de cada tela
      - tempo de pausa entre telas (on-second)
      - timeScreen (tempo que cada tela fica ativa)
      - configurações: prática, oficial, linhas, colunas, tempo de cada tela

    forçar YTA fullscreen (in progress)
    detectar saída do modo fullscreen durante o YTA
    // detectar saída da tela/aplicativo durante o YTA
    forçar YTA landscape todo o teste
    detectar saida do modo landscape durante o YTA
    guardar todos os dados possíveis sobre o user device
    quando o YTA é finalizado?
    timer
    navegação entre telas do teste (navig. Slider)
    monitorar clique duplicado
    criar função para adequar dinamicamente a altura e largura das colunas (symbols)

    Desejado:
    - tela de informação inicial
    - screens
    -- symbols
    - tela de informação intermediárias (n entre telas)
    - tela de informação final
    - system feedback (classe de erros sucesso, omissão e erro)

    dúvidas:
    - quando desabilitar o botão (symbol)?

  */

const YtaContainer = ({
  screens = [],
  symbolClasses = {},
  rows = 6,
  columns = 4,
  timeScreen = 20000,
  timeBetweenScreens = 1000
}) => {
  // const now = Date.now()

  const [currentScreen, setCurrentScreen] = useState(1)
  // const [currentScreenStartTime, setCurrentScreenStartTime] = useState(now)

  const displayScreen = currentScreen <= screens.length

  const { timer, resume } = useTimeScreen({
    timeScreen,
    timeBetweenScreens,
    stopTimer: !displayScreen
  })

  useEffect(() => {
    if (!resume) {
      setCurrentScreen((preState) => preState + 1)
    }
  }, [resume, timer])

  return (
    <>
      <p>CurrentScreen: {currentScreen}</p>
      <p>Timer: {timer}</p>
      {!resume && <p>Paused</p>}

      {resume && displayScreen && (
        <Screen
          rows={rows}
          columns={columns}
          symbols={screens?.[currentScreen - 1]?.children}
          symbolClasses={symbolClasses}
        />
      )}
    </>
  )
}

export default YtaContainer
