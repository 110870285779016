const getMissingKeys = (config, requiredKeys) =>
  requiredKeys.filter((key) => !Object.keys(config).includes(key))

export const configStatus = (config) => {
  const missingVariables = getMissingKeys(config, requiredVariables)
  const missingUrls = getMissingKeys(config.msApi, requiredUrls)

  if (missingVariables.length > 0 || missingUrls.length > 0) {
    window.alert('Environment variables not configured')
  }

  missingVariables.forEach((variable) => {
    console.error('Variable not configured in environment: ' + variable)
  })

  missingUrls.forEach((variable) => {
    console.error('URL not configured in environment: ' + variable)
  })
}

const requiredVariables = [
  'msApi',
  'waEnv',
  'gtmId',
  'idObjectclassFilteraction',
  's3BucketName',
  'region',
  'accessKeyId',
  'secretAccessKey'
]

const requiredUrls = [
  'login',
  'reload-token',
  'interaction-models',
  'interactions',
  'objects_filter'
]
