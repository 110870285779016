import { Button, Icon } from '@yes.technology/react-toolkit'
import { useRef } from 'react'

const FileUploader = ({ onChange, accept }) => {
  const hiddenFileInput = useRef(null)

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]
    onChange?.(fileUploaded)
  }

  return (
    <>
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
      />
      <Button
        variant='secondary'
        onClick={handleClick}
        style={{ width: '40px', marginLeft: '4px' }}
      >
        <Icon iconName='DevicesLocal' />
      </Button>
    </>
  )
}

export default FileUploader
