import { useCallback, useState } from 'react'
import api from '../../../../../utils/api'

import classification from './mocks/yta-mock/classification.json'
import ytasymbolclasses from './mocks/yta-mock/ytasymbolclasses.json'

const initialState = {
  classification: [],
  ytasymbolclasses: {}
}

const useFetchYtaObjects = () => {
  const [ytaObjects, setYtaObjects] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)

  /*
  WIP:

  req: workflow -> uuid_yta;

  req: yta -> classification (com ids das screens e os ids simbolos);

  req: ytasymbol e ytasymbolclass (1,2,3)

  *req: ytascreen (row, column, times) (optional)

  */
  const performFetch = useCallback(
    async ({ workflowUuid, sourceDataContainerVariable }) => {
      setIsLoading(true)

      try {
        /*
        workflow
        skip_relations???
      */
        const fetchWorkflowResponse = await api.postObjectsFilter({
          obj: {
            query: { $and: [{ 'uuid.des': { $eq: workflowUuid } }] },
            objectclass: window.workflowObjectclassUuid
          },
          showLoading: true
        })

        const workflowExecutionState = JSON.parse(
          fetchWorkflowResponse?.data?.objects?.[0]?.workflow_execution_state ||
            ''
        )
        const testAttentionUuid =
          workflowExecutionState?.state['data_container']?.[
            sourceDataContainerVariable
          ]
        testAttentionUuid

        /*
        test
      */

        /*
        Screen -> Symbolscreen
      */

        const ytaObjectsMock = {
          screens: classification,
          symbolClasses: ytasymbolclasses,
          rows: 6,
          columns: 4,
          timeScreen: 20000,
          timeBetweenScreens: 1000
        }

        setYtaObjects(ytaObjectsMock)
        setIsLoading(false)
        return []
      } catch (error) {
        console.log(error)
      }

      setIsLoading(false)
      return []
    },
    []
  )
  return {
    ytaObjects,
    isLoading,
    fetchYtaObjects: performFetch
  }
}

export default useFetchYtaObjects
