import { useCallback, useEffect, useRef, useState } from 'react'
import jwtDecode from 'jwt-decode'

import api from '../../utils/api'

import { SHOW_ALERT_FROM, SYNCHRONIZATION_ERROR_CORRECTION } from './Constants'
import { isEmbedded } from '../../utils/helpers/iframe'
import { useTranslation } from 'react-i18n-lite'
import { useAuthentication } from 'authentication'

const AuthenticationRenewal = () => {
  const alertContainerRef = useRef<HTMLDivElement>(null)
  const [timeLeft, setTimeLeft] = useState(0)
  const [stickyAlert, setStickyAlert] = useState(false)

  const { updateAuthData } = useAuthentication()

  const { token, expirationDate = 0, logout } = useAuthentication()

  const updateTimeLeft = useCallback(() => {
    if (!token) return

    const now = Math.round(Date.now() / 1000)
    const newTimeLeft = expirationDate - now - SYNCHRONIZATION_ERROR_CORRECTION
    setTimeLeft(newTimeLeft)
  }, [expirationDate, token])

  useEffect(() => {
    const interval = setInterval(updateTimeLeft, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [updateTimeLeft])

  if (token && timeLeft < 0) {
    logout()
  }

  const handleTokenReload = () => {
    api
      .postReloadToken({ token })
      .then((response) => {
        if (response && response.success && response.data?.token) {
          const decoded = jwtDecode<{ email: string; expiration_date: number }>(
            token as string
          )
          updateAuthData({
            username: decoded.email,
            token: response.data.token,
            expirationDate: response.data.expiration_date
          })
        }
      })
      .catch(({ message }) => {
        console.log(message)
      })
  }

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY
      setStickyAlert(offset > 100)
    }

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const { t } = useTranslation()

  /* 5 minutes less to avoid clock synchronization problems */
  if (!isEmbedded() && timeLeft > 0 && timeLeft < SHOW_ALERT_FROM) {
    return (
      <div
        ref={alertContainerRef}
        className={`${stickyAlert ? 'sticky-alert' : ''}`}
        style={{
          width: '100%',
          background: '#a31a1a',
          zIndex: 1000
        }}
      >
        <div
          style={{
            padding: '10px'
          }}
          className='text-white d-flex justify-content-center align-items-center'
        >
          {t('auth-renewal.session-ends', { timeLeft: String(timeLeft) })}

          <button
            type='button'
            className='btn btn-light btn-sm ml-3'
            style={{
              marginLeft: '1rem',
              textTransform: 'uppercase'
            }}
            onClick={handleTokenReload}
          >
            {t('auth-renewal.reload-session')}
          </button>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default AuthenticationRenewal
