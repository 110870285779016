import { Button, Icon } from '@yes.technology/react-toolkit'
import { EditStepProps } from './EditStep.types'
import { ComponentStructure } from './EditStep.styles'

export function EditStep({ onClick }: EditStepProps) {
  return (
    <ComponentStructure>
      <Button variant='secondary' heightClass='semiSmall' onClick={onClick}>
        <Icon iconName='Edit' />
      </Button>
    </ComponentStructure>
  )
}

export default EditStep
