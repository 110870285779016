import { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { useTranslation } from 'react-i18n-lite'

import useWindowSize from './hooks/useWindowSize'
import Player, { videoTypes } from './components/Player'

const WebcamStreamCapture = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation()

  // ainda não é permitido a escolha do tipo de vídeo, mas já estou abrindo o caminho
  const [videoType] = useState(videoTypes[0])

  // camera já está aberta?
  const [isLoading, setIsLoading] = useState(true)

  // gerenciar os dispositivos e qual é o atual
  const [deviceId, setDeviceId] = useState({})
  const [devices, setDevices] = useState([])

  useEffect(() => {
    navigator.getMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
    navigator.getMedia(
      {
        video: true
      },
      (mediaStream) => {
        setIsLoading(false)
        const track = mediaStream.getVideoTracks()[0]
        const settings = track.getSettings()
        const { deviceId: currentDeviceId } = settings
        setDeviceId(currentDeviceId)
      },
      () => undefined
    )
  }, [])

  const handleDevices = useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices]
  )

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices)
  }, [handleDevices])

  const handleChangeDevice = (deviceId) => {
    setDeviceId(deviceId)
  }

  // Pegar tamanho da tela atual e passar os valore como parametro do componente Webcam
  const size = useWindowSize()

  const isLandscape = size.height <= size.width
  const ratio = isLandscape
    ? size.width / size.height
    : size.height / size.width

  const videoConstraints = {
    facingMode: 'user',
    aspectRatio: ratio,
    deviceId
  }

  const webcamRef = useRef(null)
  const mediaRecorderRef = useRef(null)

  // Estados e métodos para criar um stream
  const [capturing, setCapturing] = useState(false)
  const [recordedChunks, setRecordedChunks] = useState([])
  const [url, setUrl] = useState('')

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data))
      }
    },
    [setRecordedChunks]
  )

  const createUrl = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new global.Blob(recordedChunks, {
        type: videoType
      })
      const urlBlob = URL.createObjectURL(blob)
      setUrl(urlBlob)
      setRecordedChunks([])
    }
  }, [recordedChunks, videoType])

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true)
    mediaRecorderRef.current = new global.MediaRecorder(
      webcamRef.current.stream,
      {
        mimeType: videoType
      }
    )
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    )
    mediaRecorderRef.current.start()
  }, [
    webcamRef,
    setCapturing,
    mediaRecorderRef,
    handleDataAvailable,
    videoType
  ])

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop()
    setCapturing(false)
    setTimeout(() => {
      createUrl()
    }, 2000)
  }, [mediaRecorderRef, setCapturing, createUrl])

  const handleDiscard = () => {
    setRecordedChunks([])
    setUrl('')
  }

  const handleCapture = () => {
    onSuccess(url)
  }

  // Após captura do vídeo
  useEffect(() => {
    if (recordedChunks.length) {
      createUrl()
    }
  }, [recordedChunks, createUrl])

  return (
    <div
      style={{
        background: '#ffffff',
        height: '100vh',
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: 2
      }}
    >
      <>
        {isLoading && (
          <div className='d-flex justify-content-center mt-4'>
            {t('hello-yes.loading')}
          </div>
        )}
        {!isLoading && deviceId && (
          <div
            className='position-relative w-100 h-100'
            style={{ backgroundColor: 'black' }}
          >
            {url && <Player source={url} />}
            {!url && (
              <Webcam
                audio
                ref={webcamRef}
                height={size.height}
                width={size.width}
                videoConstraints={videoConstraints}
              />
            )}
            {!url && (
              <div className='position-absolute bottom-0 start-0 mb-4 ms-4'>
                <button onClick={onCancel} className='btn btn-light btn-sm'>
                  {t('activity-input-take-video.btn-cancel-action')}
                </button>
              </div>
            )}
            {!capturing && url && (
              <div className='position-absolute bottom-0 start-50 translate-middle-x mb-5'>
                <div className='btn-group btn-group-sm'>
                  <button
                    onClick={handleCapture}
                    className='btn btn-dark btn-sm'
                  >
                    {t('activity-input-take-video.btn-use-this-video')}
                  </button>
                  <button
                    onClick={handleDiscard}
                    className='btn btn-danger btn-sm'
                  >
                    {t('activity-input-take-video.btn-discard')}
                  </button>
                </div>
              </div>
            )}
            {!url && (
              <div className='position-absolute bottom-0 start-50 translate-middle-x mb-4'>
                {capturing ? (
                  <button
                    onClick={handleStopCaptureClick}
                    className='btn btn-danger btn-sm'
                  >
                    {t('activity-input-take-video.btn-stop-capture')}
                  </button>
                ) : (
                  <button
                    onClick={handleStartCaptureClick}
                    className='btn btn-danger btn-sm'
                  >
                    {t('activity-input-take-video.btn-take-a-video')}
                  </button>
                )}
              </div>
            )}
            {!url && (
              <div className='position-absolute bottom-0 end-0 mb-4 me-4'>
                <div className='btn-group dropup'>
                  <button type='button' className='btn btn-light btn-sm'>
                    {t('activity-input-take-video.btn-devices')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-light btn-sm dropdown-toggle dropdown-toggle-split'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <span className='visually-hidden'>Toggle Dropdown</span>
                  </button>
                  <ul className='dropdown-menu'>
                    {devices.length > 0 &&
                      devices.map((device, key) => (
                        <li key={device.deviceId}>
                          <button
                            className={`dropdown-item ${
                              device.deviceId === deviceId ? 'active' : ''
                            }`}
                            type='button'
                            onClick={() => handleChangeDevice(device.deviceId)}
                          >
                            {device.label ||
                              `${t('activity-input-take-video.device-des')} ${
                                key + 1
                              }`}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  )
}

export default WebcamStreamCapture
