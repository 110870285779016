import { useTranslation } from 'react-i18n-lite'
import { DataFieldSelection } from '@yes.technology/react-toolkit'

function ActivityInputSelect({
  id,
  inputValue,
  options,
  cssClass,
  onChange,
  label,
  readOnly,
  required,
  helpText,
  explanationText,
  isFetchingBlueprints = false
}) {
  const { t } = useTranslation()

  const handleChange = (item) => {
    onChange(item[0].value)
  }

  const formatValue = (values) =>
    values.map((item) => ({
      id: item,
      label: item,
      value: item
    }))

  const getSelected = () =>
    formatValue(options.filter((item) => item === inputValue))

  return (
    <DataFieldSelection
      id={id}
      label={label}
      placeholder={helpText || explanationText || t('form.option.select')}
      className={`${readOnly ? '' : cssClass}`}
      selected={getSelected()}
      onChange={handleChange}
      options={formatValue(options)}
      informational={readOnly}
      required={required}
      aria-label={label}
      isLoadingBlueprints={isFetchingBlueprints}
    />
  )
}

export default ActivityInputSelect
