import styled from 'styled-components'

export const ComponentStructure = styled.div`
  width: 32px;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (min-width: 768px) {
  }
`
