import { feedbackcard } from '@yes.technology/react-toolkit'

import { VALIDATION_STATUSES } from '../../../utils/constants'

export const isActivityModelInvalid = (activityModel) => {
  const { required, validationStatus } = activityModel

  return (
    validationStatus === VALIDATION_STATUSES.INVALID ||
    (required && validationStatus !== VALIDATION_STATUSES.VALID)
  )
}

const getInvalidActivityModel = (activityModel) => {
  const isInvalid = isActivityModelInvalid(activityModel)

  if (isInvalid) {
    activityModel.validationStatus = VALIDATION_STATUSES.INVALID
  }
  return isInvalid
}

// valida activity_models obrigatórias por step
export const getInvalidFieldsInStep = (itemGroup) => {
  return itemGroup.activity_models.filter(getInvalidActivityModel)
}

export const showMessageRequiredFields = (invalidFields, t) => {
  if (invalidFields.length) {
    let errorMessage = t('step.validation.message-all-required-fields')
    if (invalidFields.length === 1) {
      errorMessage = t('step.validation.message-is-required-field', {
        field: invalidFields[0].display_text.des
      })
    }
    showError(errorMessage)
    return false
  }
  return true
}

export const showError = (title, message) => {
  if (!title) return false

  feedbackcard(title, {
    type: 'error',
    message
  })
}

export const getOnlyValidItemGroups = (itemGroups) =>
  itemGroups && itemGroups.filter((itemGroup) => itemGroup?.show !== false)

const shouldValidateGroup = (activityModelGroup, required) => {
  const groupHasSomeValue = activityModelGroup.some(
    (activityModel) => activityModel.value
  )

  return required || groupHasSomeValue
}

const validateActivityModelGroups = (activityModelGroups, required) => {
  let listInvalidActivityModelGroups = []
  for (const [
    activityModelGroupsIndex,
    activityModelGroup
  ] of activityModelGroups.entries()) {
    if (shouldValidateGroup(activityModelGroup, required)) {
      for (const activityModel of activityModelGroup) {
        if (isActivityModelInvalid(activityModel)) {
          if (!listInvalidActivityModelGroups[activityModelGroupsIndex]) {
            listInvalidActivityModelGroups[activityModelGroupsIndex] = [
              {
                ...activityModel,
                validationStatus: VALIDATION_STATUSES.INVALID
              }
            ]
          } else {
            listInvalidActivityModelGroups[activityModelGroupsIndex].push({
              ...activityModel,
              validationStatus: VALIDATION_STATUSES.INVALID
            })
          }
        }
      }
    }
  }
  return listInvalidActivityModelGroups
}

export const getInvalidActivityModelsByItemGroup = (itemGroup, t) => {
  let listInvalidActivityModels = []

  for (const activityModel of itemGroup.activity_models) {
    if (activityModel.content_type === 'activity_group') {
      const validatedActivityModelGroups = validateActivityModelGroups(
        activityModel.activity_models,
        activityModel.required
      )
      if (validatedActivityModelGroups.length) {
        listInvalidActivityModels.push({
          ...activityModel,
          activity_models: validatedActivityModelGroups
        })
      }
    } else if (activityModel.content_type === 'agreement_term') {
      if (isActivityModelInvalid(activityModel)) {
        listInvalidActivityModels.push({
          error_text: {
            des: itemGroup.des
          },
          ...activityModel,
          validationStatus: VALIDATION_STATUSES.INVALID
        })
      }
    } else {
      if (isActivityModelInvalid(activityModel)) {
        listInvalidActivityModels.push({
          ...activityModel,
          validationStatus: VALIDATION_STATUSES.INVALID
        })
      }
    }
  }

  showRequiredMessageActivityModels(listInvalidActivityModels, t)

  return listInvalidActivityModels
}

export const showRequiredMessageActivityModels = (
  activityModels,
  t,
  ActivityModelGroupsIndex
) => {
  if (!activityModels.length) return true

  let errorMessage = ''

  if (activityModels.length > 1) {
    errorMessage = t('step.validation.message-all-required-fields')
  } else if (activityModels.length === 1) {
    const isGroup = activityModels.filter(
      (activityModel) => activityModel.content_type === 'activity_group'
    )
    if (isGroup.length) {
      activityModels[0].activity_models.map((ActivityModelGroups, index) =>
        showRequiredMessageActivityModels(ActivityModelGroups, t, index)
      )
    } else {
      const inValidFormat =
        activityModels?.[0].validation_regex &&
        activityModels?.[0].value &&
        activityModels?.[0].value !== ''

      if (ActivityModelGroupsIndex >= 0) {
        errorMessage = t(
          `${
            inValidFormat
              ? 'step.validation.message-invalid-format-in-activity-group'
              : 'step.validation.message-is-required-field-in-activity-group'
          }`,
          {
            field: activityModels?.[0].display_text.des,
            groupNumber: ActivityModelGroupsIndex + 1
          }
        )
      } else if (activityModels?.[0].content_type === 'agreement_term') {
        errorMessage = activityModels?.[0].error_text.des
      } else {
        errorMessage = t(
          `${
            inValidFormat
              ? 'step.validation.message-invalid-format'
              : 'step.validation.message-is-required-field'
          }`,
          {
            field: activityModels?.[0].display_text.des
          }
        )
      }
    }
  }

  showError(errorMessage)
}

const findInvalidActivitymodelByUuid = (listInvalidActivityModels, uuid) =>
  listInvalidActivityModels?.find(
    (invalidActivityModel) => invalidActivityModel.uuid === uuid
  )

export const updateItemGroupsWithInvalidActivities = (
  interactionModelItemGroups,
  currentInteractionModelItemGroup,
  listInvalidActivityModels
) => {
  return interactionModelItemGroups.map((interactionModelItemGroup) => {
    if (
      interactionModelItemGroup.uuid === currentInteractionModelItemGroup.uuid
    ) {
      return {
        ...interactionModelItemGroup,
        activity_models: interactionModelItemGroup.activity_models.map(
          (firstLevelActivityModel, indexFirstLevelActivityModel) => {
            if (firstLevelActivityModel.content_type === 'activity_group') {
              return {
                ...firstLevelActivityModel,
                activity_models: firstLevelActivityModel.activity_models.map(
                  (secondLevelActivityModels, indexSecondLevelActivityModels) =>
                    secondLevelActivityModels.map(
                      (activityModel) =>
                        findInvalidActivitymodelByUuid(
                          listInvalidActivityModels?.[
                            indexFirstLevelActivityModel
                          ]?.activity_models?.[indexSecondLevelActivityModels],
                          activityModel.uuid
                        ) || activityModel
                    )
                )
              }
            } else {
              return (
                findInvalidActivitymodelByUuid(
                  listInvalidActivityModels,
                  firstLevelActivityModel.uuid
                ) || firstLevelActivityModel
              )
            }
          }
        )
      }
    }
    return interactionModelItemGroup
  })
}
