import { useEffect, useRef, useState } from 'react'

const useTimeScreen = ({
  timeScreen = 20000,
  timeBetweenScreens = 1000,
  stopTimer = false
}) => {
  const timeRef = useRef()

  const [timer, setTimer] = useState(0)
  const [resume, setResume] = useState(true)

  // resume timer after timeBetweenScreens
  useEffect(() => {
    if (!resume) {
      const timeout = setTimeout(() => {
        setResume(true)
      }, timeBetweenScreens)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [resume, timeBetweenScreens])

  // pause timer if timeScreen ends
  useEffect(() => {
    if (timer >= timeScreen / 1000) {
      clearInterval(timeRef.current)
      setResume(false)
      setTimer(0)
    }
  }, [timer, timeScreen])

  // timer
  useEffect(() => {
    if (resume && !stopTimer) {
      timeRef.current = setInterval(() => {
        setTimer((preState) => preState + 1)
      }, 1000)

      return () => {
        clearInterval(timeRef.current)
      }
    }
  }, [resume, stopTimer])

  return {
    timer,
    resume
  }
}

export default useTimeScreen
