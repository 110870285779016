import { useEffect, useState } from 'react'

/**
 * @deprecated
 * workaround to solve deferred state on input
 */
const useRedundantState = ({ value }) => {
  const [redundantValue, setRedundantValue] = useState('')

  useEffect(() => {
    setRedundantValue(value)
  }, [value])

  return {
    redundantValue,
    setRedundantValue
  }
}

export default useRedundantState
