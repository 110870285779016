import { VALIDATION_STATUSES } from './constants'
import { ActivityModel } from 'types/shared'
import { isValidDateInterval, isValidDateTime } from './date'

const hasValidationCallback = (activityModel: ActivityModel) =>
  !!activityModel.validation_activity_callback

type ErrorMatcher = {
  message: string
  matcher: (
    value: NonNullable<ActivityModel['value']>,
    activityModel: ActivityModel
  ) => boolean
}

const emptinessErrorMatcher: ErrorMatcher = {
  message: 'activity-input.validation.required-field',
  matcher: (value, activityModel) => {
    const isAutocompleteMultiselection =
      activityModel.content_type === 'autocomplete_multiselection'
    const isEmptyValue =
      value === '' ||
      (activityModel.information_request_callback &&
        typeof value !== 'object' &&
        !isAutocompleteMultiselection)

    return Boolean(activityModel.required && isEmptyValue)
  }
}

const regexErrorMatcher: ErrorMatcher = {
  message: 'activity-input.validation.wrong_format',
  matcher: (value, activityModel) =>
    Boolean(
      activityModel.content_type !== 'date' &&
        activityModel.content_type !== 'datetime' &&
        'validation_regex' in activityModel &&
        activityModel.validation_regex &&
        value !== '' &&
        !new RegExp(activityModel.validation_regex).test(value.toString())
    )
}

const autocompleteErrorMatcher: ErrorMatcher = {
  message: 'activity-input.validation.required-field',
  matcher: (value, activityModel) => {
    const isString = activityModel.content_type === 'string'
    const hasFilteraction = !!activityModel.information_request_filteraction

    const isAutocomplete =
      isString && (hasValidationCallback(activityModel) || hasFilteraction)

    return Boolean(
      isAutocomplete &&
        activityModel.required &&
        (typeof value === 'string' || (!value.id && !value.des))
    )
  }
}

const dateErrorMatcher: ErrorMatcher = {
  message: 'activity-input.validation.wrong_format',
  matcher: (value, activityModel) =>
    (activityModel.content_type === 'date' ||
      activityModel.content_type === 'datetime') &&
    !isValidDateTime({
      dateTime: typeof value === 'string' ? value : '',
      minYear: activityModel.minimum_year,
      maxYear: activityModel.maximum_year,
      contentType: activityModel.content_type,
      lang: 'pt-BR'
    })
}

const dateIntervalErrorMatcher: ErrorMatcher = {
  message: 'activity-input.validation.invalid-range',
  matcher: (value, activityModel) =>
    (activityModel.content_type === 'date' ||
      activityModel.content_type === 'datetime') &&
    !isValidDateInterval({
      dateTime: typeof value === 'string' ? value : '',
      minDate: activityModel.valid_min_date,
      maxDate: activityModel.valid_max_date,
      lang: 'pt-BR'
    })
}

const documentErrorMatcher: ErrorMatcher = {
  message: 'activity-input.validation.required-field',
  matcher: (value, activityModel) => {
    let parsedValue: { file?: string } = {
      file: ''
    }
    try {
      parsedValue = JSON.parse(
        typeof value === 'string' ? value : '{ "file": "" }'
      )
    } catch (e) {}

    return activityModel.content_type === 'document_photo' && !parsedValue.file
  }
}

const errorMatchers = [
  emptinessErrorMatcher,
  regexErrorMatcher,
  autocompleteErrorMatcher,
  dateErrorMatcher,
  dateIntervalErrorMatcher,
  documentErrorMatcher
]

export default function validateActivityValue({
  activityModel
}: {
  activityModel: ActivityModel
}) {
  const { value = '' } = activityModel
  const normalizedValue =
    typeof value === 'object' || isNaN(parseInt(value))
      ? value
      : value.toString()

  const errorMessage = errorMatchers.find(({ matcher }) =>
    matcher(normalizedValue, activityModel)
  )?.message

  if (errorMessage) {
    return {
      validationStatus: VALIDATION_STATUSES.INVALID,
      helperText: errorMessage
    }
  }

  const shouldCallValidationCallback =
    hasValidationCallback(activityModel) && normalizedValue !== ''
  const isEmptyOptionalValue = normalizedValue === '' && !activityModel.required

  if (!shouldCallValidationCallback && !isEmptyOptionalValue) {
    return {
      validationStatus: VALIDATION_STATUSES.VALID,
      helperText: 'activity-input.validation.is_valid'
    }
  }

  return { validationStatus: VALIDATION_STATUSES.PENDING }
}
