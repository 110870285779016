import { useSelector } from 'react-redux'
import { FeedbackCardContainer } from '@yes.technology/react-toolkit'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../asset/css/default.css'

import { useHandleLanguage } from './hooks'
import Loading from './Loading'
import AuthenticationRenewal from './AuthenticationRenewal'
import RedirectToLogin from './RedirectToLogin'
import ServiceWorkerWrapper from './ServiceWorkerBox/ServiceWorkerWrapper'
import EnvironmentWarning from './EnvironmentWarning'
import NetworkDetector from './NetworkDetector'
import Router from './Router'
import useToken from './useToken'
import useWorkflow from './Workflow'

global.jQuery = require('jquery')
require('bootstrap')

const HelloYes = () => {
  const isLoading = useSelector((state) => state.api.isPending)

  useHandleLanguage()
  useToken()
  useWorkflow()

  return (
    <>
      <Loading active={isLoading} />
      <AuthenticationRenewal />
      <RedirectToLogin />
      <ServiceWorkerWrapper />
      <EnvironmentWarning />
      <NetworkDetector />
      <Router />
      <FeedbackCardContainer />
    </>
  )
}

export default HelloYes
