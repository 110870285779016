import { Button, Icon } from '@yes.technology/react-toolkit'
import { ComponentStructure } from './GoToTop.styles'

const GoToTop = () => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    <ComponentStructure>
      <Button heightClass='semiSmall' onClick={goToTop}>
        <Icon iconName='DropdownArrowUp' />
      </Button>
    </ComponentStructure>
  )
}

export default GoToTop
