import { AutocompleteMultiselection } from '@yes.technology/react-toolkit'
import { useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import debounce from 'lodash.debounce'

import useFetchSuggestions from 'modules/interaction/hooks/useFetchSuggestions'

const getOptions = ({ selectedOptions, suggestions }) => {
  const mappedSuggestions = suggestions.map(({ uuid, des }) => ({
    id: uuid,
    value: des
  }))

  const uniqueSelectedOptions = selectedOptions.filter(
    (selectedOption) =>
      !suggestions.find((suggestion) => suggestion.uuid === selectedOption.id)
  )

  const options = [...uniqueSelectedOptions, ...mappedSuggestions]

  return options
}

const getSelectedOptions = (value) => {
  if (!value || typeof value.id !== 'string') {
    return []
  }

  const uuids = value.id.split(', ')
  const descriptions = value.des.split(', ')

  const selectedOptions = uuids.map((uuid, index) => ({
    id: uuid,
    value: descriptions[index]
  }))

  return selectedOptions
}

const ActivityAutocompleteMultiselection = ({
  id,
  filteraction,
  filteractionParams,
  value,
  onChange,
  endpoint,
  statusClass,
  isFetchingBlueprints = false,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('')

  const selectedOptions = useMemo(() => getSelectedOptions(value), [value])

  const { interactionModel } = useSelector(({ interactions }) => interactions)

  const interactionModelItemGroupsState =
    interactionModel?.data?.interaction_model_item_groups

  const { suggestions, fetchSuggestions, resetSuggestions, isLoading } =
    useFetchSuggestions({ id, filteraction, filteractionParams, endpoint })

  const debouncedFetch = useMemo(
    () =>
      debounce((value) => {
        fetchSuggestions(value, interactionModelItemGroupsState)
      }, 350),
    [fetchSuggestions, interactionModelItemGroupsState]
  )

  const options = useMemo(
    () => getOptions({ selectedOptions, suggestions }),
    [selectedOptions, suggestions]
  )

  const onSelect = useCallback(
    (targetSelection) => {
      const isOptionAlreadySelected = selectedOptions.find(
        ({ id }) => id === targetSelection.id
      )

      const optionsAfterSelection = [...selectedOptions, targetSelection]
      const optionsAfterDeselection = selectedOptions.filter(
        ({ id }) => id !== targetSelection.id
      )

      const newSelectedOptions = isOptionAlreadySelected
        ? optionsAfterDeselection
        : optionsAfterSelection

      const uuids = newSelectedOptions.map((option) => option.id)
      const descriptions = newSelectedOptions.map((option) => option.value)

      onChange({ id: uuids.join(', '), des: descriptions.join(', ') })
    },
    [onChange, selectedOptions]
  )

  const handleOnChange = (value) => {
    if (value === '') {
      resetSuggestions()
    } else {
      debouncedFetch(value)
    }

    setInputValue(value)
  }

  return (
    <AutocompleteMultiselection
      {...rest}
      statusClass={isLoading ? 'processing' : statusClass}
      onChange={handleOnChange}
      value={inputValue}
      isLoadingBlueprints={isFetchingBlueprints}
      {...{ options, selectedOptions, onSelect }}
    />
  )
}

export default ActivityAutocompleteMultiselection
