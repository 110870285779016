const getDependencyUuid = (dependency, currentIndexActivityGroup) => {
  return currentIndexActivityGroup === undefined
    ? dependency
    : `${dependency}-${currentIndexActivityGroup}`
}

const getValueActivityModel = (value) =>
  typeof value === 'string' ? value : value?.des

const getValueByDependency = (
  activitiesByUuid,
  dependency,
  currentIndexActivityGroup
) => {
  return getValueActivityModel(
    activitiesByUuid[getDependencyUuid(dependency, currentIndexActivityGroup)]
      ?.value
  )
}

export default function useAddressFromModel({
  activitiesByUuid,
  dependencies,
  currentIndexActivityGroup
}) {
  const state = getValueByDependency(
    activitiesByUuid,
    dependencies.state,
    currentIndexActivityGroup
  )
  const city = getValueByDependency(
    activitiesByUuid,
    dependencies.city,
    currentIndexActivityGroup
  )
  const street = getValueByDependency(
    activitiesByUuid,
    dependencies.street,
    currentIndexActivityGroup
  )
  const number = getValueByDependency(
    activitiesByUuid,
    dependencies.number,
    currentIndexActivityGroup
  )
  const neighborhood = getValueByDependency(
    activitiesByUuid,
    dependencies.neighborhood,
    currentIndexActivityGroup
  )
  const zipCode = getValueByDependency(
    activitiesByUuid,
    dependencies.zip_code,
    currentIndexActivityGroup
  )
  const filledAddressFields = [
    street,
    number,
    neighborhood,
    city,
    state,
    zipCode
  ].filter(Boolean)
  const requiredAddressFields = [street, city, state]
  const isAddressFilled = requiredAddressFields.every(Boolean)
  const address = isAddressFilled ? filledAddressFields.join(', ') : ''

  return address
}
