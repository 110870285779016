import { useState } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { Unifree } from '@yes.technology/react-toolkit'

import debounce from 'lodash.debounce'
import useGeocodeCallback from '../hooks/useGeocodeCallback'

function LocationInput({ onChange }) {
  const { t } = useTranslation()
  const geocodeCallback = useGeocodeCallback()

  const [address, setAddress] = useState('')

  const handleDebouncedAddress = debounce((newValue) => {
    newValue &&
      geocodeCallback({ address: newValue })
        .then((res) => {
          if (res.results.length === 0) return

          const geoLocation = res.results[0].geometry.location

          onChange({ lat: geoLocation.lat(), lng: geoLocation.lng() })
        })
        .catch((error) => {
          console.error(error)
        })
  }, 500)

  const handleAddressChange = (newVal) => {
    setAddress(newVal)
    handleDebouncedAddress(newVal)
  }

  return (
    <div className='mb-2 px-3'>
      <Unifree
        heightClass='medium'
        label={t('activity-input-geolocation.labels.address')}
        placeholder={t('activity-input-geolocation.input-info')}
        value={address}
        onChange={handleAddressChange}
      />
    </div>
  )
}

export default LocationInput
