import { useContext } from 'react'
import { AuthenticationContext } from './AuthenticationProvider'

const useAuthentication = () => {
  const authContext = useContext(AuthenticationContext)

  if (!authContext) {
    throw new Error(
      'AuthenticationContext not configured. Did you forget to wrap your component with AuthenticationProvider?'
    )
  }

  return authContext
}

export default useAuthentication
