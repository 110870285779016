import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import TagManager from 'react-gtm-module'
import { createBrowserHistory as createHistory } from 'history'
import ReactModal from 'react-modal'
import { TranslationContainer } from 'react-i18n-lite'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from '@yes.technology/react-toolkit'

import store, { persistor } from './state/store'
import HelloYes from './modules/shared/HelloYes'
import apiAsync from './utils/api-async'
import { configStatus } from './utils/configStatus'
import ResizeDetector from './modules/shared/ResizeDetector'
import locales, { defaultLanguage } from './modules/shared/I18n'
import { AuthenticationProvider } from 'authentication'
import apiUtils from 'utils/api'

// TODO: Check if this require is really necessary
// eslint-disable-next-line @typescript-eslint/no-var-requires
window.Buffer = require('buffer/').Buffer

ReactModal.setAppElement('#root')

const history = createHistory()

if (window.waEnv === 'production' && window.gtmId) {
  const tagManagerArgs = {
    gtmId: window.gtmId
  }
  TagManager.initialize(tagManagerArgs)
}

configStatus(window)
apiAsync.setConfig(window.msApi || {})
apiAsync.setDispatch(store.dispatch)
apiUtils.setDispatch(store.dispatch)

const prepare = async () => {
  if (
    process.env.NODE_ENV === 'development' &&
    window.location.hostname === 'localhost'
  ) {
    const { default: worker } = await import('./api/mocks/serviceWorker')
    return worker.start({ onUnhandledRequest: 'bypass' })
  }

  return Promise.resolve()
}

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

prepare()
  .then(() =>
    root.render(
      <ThemeProvider theme={defaultTheme}>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <TranslationContainer
                defaultLanguage={defaultLanguage}
                locales={locales}
              >
                <AuthenticationProvider>
                  <ResizeDetector>
                    <HelloYes />
                  </ResizeDetector>
                </AuthenticationProvider>
              </TranslationContainer>
            </Router>
          </PersistGate>
        </ReduxProvider>
      </ThemeProvider>
    )
  )
  .catch(console.error)
