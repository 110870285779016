import { datepickerToISOFormat } from '@yes.technology/react-toolkit'
import { DEFAULT_MIN_MAX_YEARS_FROM_TODAY } from '../modules/shared/Constants'

export type IsValidDateIntervalProps = {
  dateTime: string
  minDate?: string
  maxDate?: string
  lang: AllowedLocales
}

type Props = {
  dateTime: string
  minYear?: number
  maxYear?: number
  lang: string
  contentType?: string
}

// TODO: Remove type duplication
export type AllowedLocales = 'en-US' | 'pt-BR' | 'de-DE'

/**
 *  date format:      'dd/mm/aaaa' or 'dd-mm-aaaa'
 *  date format US:   'mm/dd/yyyy' or 'mm-dd-aaaa'
 *  time format:      'hh:mm:ss'
 *  dateTime formant: '<date> | <time>'
 *
 *  contentType:      'date' or 'datetime'
 *  lang:             'en-US' or 'pt-BR' etc.
 */
export const isValidDateTime = ({
  dateTime,
  minYear,
  maxYear,
  lang,
  contentType = 'date'
}: Props) => {
  const isValidDate = (dateOnly: string) => {
    const normalizedDate = dateOnly.replace(/-/g, '/')

    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(normalizedDate)) {
      return false
    }

    const dateMs = new Date(normalizedDate).getTime()
    const yearNow = new Date().getFullYear()

    const minYearMs = new Date(
      minYear || yearNow - DEFAULT_MIN_MAX_YEARS_FROM_TODAY,
      0,
      1
    ).getTime()

    if (dateMs < minYearMs) {
      return false
    }

    const maxYearMs = new Date(
      maxYear || yearNow + DEFAULT_MIN_MAX_YEARS_FROM_TODAY,
      0,
      1
    ).getTime()

    if (dateMs > maxYearMs) {
      return false
    }

    const dateArray = normalizedDate.split('/')
    const day = dateArray[lang === 'en-US' ? 1 : 0]
    const month = dateArray[lang === 'en-US' ? 0 : 1]
    const year = dateArray[2]

    const verifyingDate = new Intl.DateTimeFormat('en-US').format(
      new Date(+year, +month - 1, +day)
    )
    const referenceDate = `${+month}/${+day}/${+year}`

    return verifyingDate === referenceDate
  }

  const isValidTime = (time: string) => {
    if (!/^\d{2}\:\d{2}\:\d{2}$/.test(time)) {
      return false
    }

    const [hour, min, sec] = time.split(':')

    return (
      +hour >= 0 &&
      +hour < 24 &&
      +min >= 0 &&
      +min < 60 &&
      +sec >= 0 &&
      +sec < 60
    )
  }

  const [date, time] = dateTime.split('|')

  if (contentType === 'datetime') {
    return (
      isValidDate(date.trim()) && time.length > 0 && isValidTime(time.trim())
    )
  }

  return isValidDate(date.trim())
}

/**
 *  date format:      'dd/mm/aaaa' or 'dd-mm-aaaa'
 *  date format US:   'mm/dd/yyyy' or 'mm-dd-aaaa'
 *  time format:      'hh:mm:ss'
 *  dateTime formant: '<date> | <time>' (aka DatePickerFormat)
 *
 *  (min|max)Date: ISO format Eg. YYYY-MM-DDThh:mm:ss or YYYY-MM-DD
 *
 *  contentType:      'date' or 'datetime'
 *  lang:             'en-US' or 'pt-BR' etc.
 */
export const isValidDateInterval = ({
  dateTime,
  minDate,
  maxDate,
  lang
}: IsValidDateIntervalProps): boolean => {
  const date = new Date(datepickerToISOFormat(dateTime, lang) || '')

  const min = minDate ? new Date(minDate) : null
  const max = maxDate ? new Date(maxDate) : null

  const isAfterMinDate = min ? date >= min : true
  const isBeforeMaxDate = max ? date <= max : true

  return isAfterMinDate && isBeforeMaxDate
}
