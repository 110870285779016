import { useEffect } from 'react'

import useAsyncState from '../../../utils/useAsyncState'
import {
  apiStatus,
  apiStatusAction
} from '../../../utils/apiSituationStatusAndActions'

export default function useStatus({ objectClass, idObject, statusCode }) {
  const [objectStatus, setObjectStatus] = useAsyncState()
  const [statuses, setStatuses] = useAsyncState([])
  const [statusActions, setStatusActions] = useAsyncState([])
  const [visibleStatusActions, setVisibleStatusActions] = useAsyncState([])
  const [isFetchingStatuses, setIsFetchingStatuses] = useAsyncState(true)
  const [isFetchingActions, setIsFetchingActions] = useAsyncState(true)
  const [isLoading, setIsLoading] = useAsyncState(true)

  const fetchStatuses = () => {
    const requestFilters = {
      'objectclass.uuid': {
        $in: [objectClass]
      }
    }

    apiStatus({ $and: requestFilters, loading: false })
      .then((response) => {
        setStatuses(response?.data?.objects || [])
      })
      .finally(() => {
        setIsFetchingStatuses(false)
      })
  }

  const fetchStatusActions = () => {
    if (!isFetchingStatuses) {
      const requestFilters = {
        'destination_status.uuid': {
          $in: statuses.map((status) => status.uuid)
        }
      }

      const performFetch = () =>
        apiStatusAction({ $and: requestFilters, loading: false })
          .then((response) => {
            setStatusActions(response?.data?.objects || [])
          })
          .finally(() => {
            setIsFetchingActions(false)
          })

      statuses.length ? performFetch() : setIsFetchingActions(false)
    }
  }

  const updateObjectStatus = () => {
    const initialStatusAction = statusActions.find(
      (statusAction) => !statusAction.allowed_origin_status
    )
    const initialStatus = initialStatusAction?.destination_status?.[0]

    const objectStatusCode = idObject ? statusCode : initialStatus?.code
    const newObjectStatus = statuses.find(
      ({ code }) => code === objectStatusCode
    )

    objectStatusCode && setObjectStatus(newObjectStatus)
  }

  const updateVisibleStatusActions = () => {
    if (!isFetchingActions) {
      const getVisibleActions = (statusAction) =>
        objectStatus &&
        statusAction.allowed_origin_status?.[0]?.code === objectStatus.code
      const newVisibleStatusActions = statusActions.filter(getVisibleActions)
      setVisibleStatusActions(newVisibleStatusActions)
      setIsLoading(false)
    }
  }

  useEffect(fetchStatuses, [objectClass, setIsFetchingStatuses, setStatuses])

  useEffect(fetchStatusActions, [
    statuses,
    isFetchingStatuses,
    setIsFetchingActions,
    setStatusActions
  ])

  useEffect(updateObjectStatus, [
    statusActions,
    statuses,
    idObject,
    statusCode,
    setObjectStatus
  ])

  useEffect(updateVisibleStatusActions, [
    statusActions,
    objectStatus,
    isFetchingActions,
    setIsLoading,
    setVisibleStatusActions
  ])

  return { objectStatus, visibleStatusActions, isLoading }
}
