const Alert = ({ text, buttonText, onClick }) => {
  return (
    <div className='alert alert-info mb-0 text-center'>
      {text}{' '}
      {buttonText && (
        <button className='btn btn-dark btn-sm ml-2' onClick={onClick}>
          {buttonText}
        </button>
      )}
    </div>
  )
}

export default Alert
