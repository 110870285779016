import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18n-lite'
import {
  sendMessageToParent,
  useDisplayUserError
} from '@yes.technology/react-toolkit'
import { useDispatch } from 'react-redux'

import {
  saveInteraction,
  validateInteractionModelCallback,
  validateInteractionModelItemGroupCallback
} from '../../../utils/interactionModel'
import {
  getInvalidFieldsInStep,
  showMessageRequiredFields,
  getOnlyValidItemGroups,
  getInvalidActivityModelsByItemGroup,
  updateItemGroupsWithInvalidActivities
} from '../utils/validation'
import { interactionModelOperations } from '../redux'

const useStep = (interactionModel, onSave) => {
  const { t, language } = useTranslation()

  const dispatch = useDispatch()

  const [totalSteps, setTotalSteps] = useState(1)
  const [currentStep, setCurrentStep] = useState(1)
  const [currentStepData, setCurrentStepData] = useState({})
  const [uuidInteraction, setUuidInteraction] = useState(null)
  const [isLoadedSteps, setIsLoadedSteps] = useState(false)
  const [isInteractionFinished, setIsInteractionFinished] = useState(false)

  const steps = useMemo(
    () =>
      getOnlyValidItemGroups(
        interactionModel?.data?.interaction_model_item_groups || []
      ),
    [interactionModel]
  )

  const initialStepUuid = interactionModel.data.initial_step_uuid
  const initialStep = steps.find((step) => step.uuid === initialStepUuid)
  const initialStepIndex = steps.indexOf(initialStep) + 1

  useEffect(() => {
    setCurrentStep(initialStepIndex || 1)
  }, [initialStepIndex])

  useEffect(() => {
    setTotalSteps(steps && steps.length ? steps.length + 1 : 1)

    setCurrentStepData(
      steps && steps[currentStep - 1] ? steps[currentStep - 1] : []
    )
  }, [steps, currentStep])

  useEffect(() => {
    if (totalSteps === 1) return
    setIsLoadedSteps(true)
  }, [totalSteps])

  useEffect(() => {
    if (
      isInteractionFinished &&
      !interactionModel?.isFetching &&
      uuidInteraction
    ) {
      const informationCards = interactionModel?.data?.information_cards
      const hasConclusionCard = informationCards?.some(
        (card) => card.display_on === 'interaction-finished'
      )

      if (!hasConclusionCard) {
        sendMessageToParent(interactionModel.workflowUuid, uuidInteraction)
      }
    }
  }, [isInteractionFinished, interactionModel, uuidInteraction])

  const { displayUserError } = useDisplayUserError()

  const saveAndFinish = async (data) => {
    const invalidFields = steps.map(getInvalidFieldsInStep).flat()

    if (!showMessageRequiredFields(invalidFields, t)) {
      return false
    }

    if (data.validation_callback) {
      const responseValidationCallback = await validateInteractionModelCallback(
        data,
        data.validation_callback
      )
      if (!responseValidationCallback?.success) {
        displayUserError({ error: responseValidationCallback })
        return false
      }
    }

    const response = await saveInteraction(interactionModel, language).catch(
      (error) => {
        return {
          success: false,
          status: error?.status,
          message: error?.message || 'Failed Request'
        }
      }
    )

    if (!response.success && response.status !== 503) {
      displayUserError({
        title: t('step.save.failure-title'),
        userMessageFallback: t('step.save.failure-message'),
        error: response
      })

      console.error(response.message)

      return false
    }

    const newUuidInteraction = response?.data?.new_interaction_ids?.[0]
    setUuidInteraction(newUuidInteraction)
    onSave?.()
    setIsInteractionFinished(true)
  }

  const afterNextButton = async (step) => {
    const currentInteractionModelItemGroup = steps[currentStep - 1]
    const listInvalidActivityModels = getInvalidActivityModelsByItemGroup(
      currentInteractionModelItemGroup,
      t
    )
    if (listInvalidActivityModels.length) {
      const validatedInteractionModelItemGroups =
        updateItemGroupsWithInvalidActivities(
          interactionModel?.data?.interaction_model_item_groups || [],
          currentInteractionModelItemGroup,
          listInvalidActivityModels
        )
      dispatch(
        interactionModelOperations.updateItemGroups(
          validatedInteractionModelItemGroups
        )
      )
      return false
    }

    if (currentInteractionModelItemGroup?.validation_group_callback) {
      const responseValidationCallback =
        await validateInteractionModelItemGroupCallback(
          currentInteractionModelItemGroup,
          currentInteractionModelItemGroup.validation_group_callback
        )
      if (!responseValidationCallback?.success) {
        displayUserError({ error: responseValidationCallback })
        return false
      }
    }

    setCurrentStep(step)
    setCurrentStepData(steps[step - 1])
  }

  const changeStep = (step) => {
    const finishingInteraction = step > totalSteps
    const isAnyStepWithValidation = step > currentStep

    // clicou no botão Salvar e Ativar
    if (finishingInteraction) {
      saveAndFinish(interactionModel?.data)
    }

    // clicou no botão avançar
    // qualquer step, exceto finish e preview (sempre pelo botão next)
    if (!finishingInteraction && isAnyStepWithValidation) {
      afterNextButton(step)
    }

    // preview e também steps que estão voltando (botão previous)
    if (!finishingInteraction && !isAnyStepWithValidation) {
      setCurrentStep(step)
    }

    window.scrollTo(0, 0)
  }

  return {
    totalSteps,
    currentStep,
    currentStepData,
    uuidInteraction,
    setCurrentStep,
    setCurrentStepData,
    setTotalSteps,
    setUuidInteraction,
    initialStepIndex,
    changeStep,
    isLoadedSteps,
    isInteractionFinished
  }
}

export default useStep
