import { useEffect, useMemo, useCallback, useRef } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'

export default function useGeocodeCallback() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: window.googleMapsApiKey
  })

  const isLoadedResolveRef = useRef(() => {})
  const isLoadedPromise = useMemo(
    () =>
      new Promise((resolve) => {
        isLoadedResolveRef.current = resolve
      }),
    []
  )

  useEffect(() => {
    if (isLoaded) {
      isLoadedResolveRef.current()
    }
  }, [isLoaded])

  return useCallback(
    async (...params) => {
      await isLoadedPromise
      const { geocode } = new window.google.maps.Geocoder()

      return geocode(...params)
    },
    [isLoadedPromise]
  )
}
