import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18n-lite'

export const videoTypes = ['video/webm']

const Player = ({ source, type, style }) => {
  const { t } = useTranslation()

  return (
    <video controls style={style}>
      <source src={source} type={type} />
      <p>{t('video-player.no-support-for-html5-video-tag')}</p>
    </video>
  )
}

Player.propTypes = {
  source: PropTypes.string.isRequired,
  type: PropTypes.string,
  style: PropTypes.shape({
    [PropTypes.string]: PropTypes.string
  })
}

Player.defaultProps = {
  type: videoTypes[0],
  style: {
    objectFit: 'contain',
    width: '100%',
    height: '100%'
  }
}

export default Player
