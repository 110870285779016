import { format } from 'date-fns'
import { ptBR, enUS, de } from 'date-fns/locale'

export function formatDate(date, ...formatArgs) {
  return format(new Date(date), ...formatArgs)
}

export function getLocaleByLanguage(language) {
  switch (language) {
    case 'en-US':
      return enUS
    case 'de-DE':
      return de
    default:
      return ptBR
  }
}
