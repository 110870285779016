import { useCallback, useEffect, useState } from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader
} from '@react-google-maps/api'

import mapPinIcon from '../../asset/img/map-pin.png'

const { googleMapsApiKey } = window

const MapView = ({ markers, mapStyles, onMapClick }) => {
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey })

  const [activeMarker, setActiveMarker] = useState(null)

  const [map, setMap] = useState(null)

  const onLoad = useCallback((map) => setMap(map), [])

  const onClick = useCallback(
    (e) => {
      setActiveMarker(null)
      onMapClick && onMapClick(e)
    },
    [onMapClick]
  )

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()
      markers.forEach((marker) => {
        bounds.extend({
          lat: Number(marker.lat),
          lng: Number(marker.lng)
        })
      })
      map.fitBounds(bounds)
    }
  }, [map, markers])

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoad}
      onClick={onClick}
      mapContainerStyle={{
        width: '100%',
        height: '172px',
        borderRadius: '4px',
        ...mapStyles
      }}
      options={{
        disableDefaultUI: true,
        zoomControl: true
      }}
    >
      {markers.map(({ uuid, des, complement, total, lat, lng, onDragEnd }) => (
        <Marker
          key={uuid}
          position={{ lat: Number(lat), lng: Number(lng) }}
          onDragEnd={onDragEnd}
          onClick={() => setActiveMarker(uuid)}
          animation={window.google.maps.Animation.DROP}
          draggable={!!onDragEnd}
          icon={mapPinIcon}
        >
          {activeMarker === uuid ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                <p className='fs-6 text-uppercase fw-bold'>{des}</p>
                <p className='fs-6'>
                  {complement}: <span className='fw-bold'>{total}</span>
                </p>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default MapView
