import { combineReducers } from 'redux'
import { interactionModelReducer } from './slice'

export const interactionModel = interactionModelReducer

const interactions = combineReducers({
  interactionModel
})

export default interactions
