import { useCallback, useState } from 'react'

export default function useModal(initialIsOpen = false) {
  const [isOpenModal, setisOpenModal] = useState(initialIsOpen)

  const openModal = useCallback(() => {
    setisOpenModal(true)
  }, [])

  const closeModal = useCallback(() => {
    setisOpenModal(false)
  }, [])

  return { isOpenModal, openModal, closeModal }
}
