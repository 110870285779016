import AWS from 'aws-sdk'

// definir parâmetros de acesso ao S3
const S3_BUCKET = window.s3BucketName
const REGION = window.region

AWS.config.update({
  accessKeyId: window.accessKeyId,
  secretAccessKey: window.secretAccessKey
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
})

const useGetObjectS3 = () => {
  const getObject = (filename) => {
    return new Promise((resolve) => {
      myBucket.getObject({ Key: filename }, (err, file) => {
        console.log(err)
        const params = {
          contentLength: file.ContentLength,
          contentType: file.ContentType,
          image: 'data:image/jpeg;base64,' + encode(file.Body)
        }
        resolve(params)
      })
    })
  }
  return {
    getObject
  }
}

function encode(data) {
  let buf = Buffer.from(data)
  let base64 = buf.toString('base64')
  return base64
}

export default useGetObjectS3
