import apiAsync from '../../utils/api-async'
import { useQuery } from './useQuery'
import { useAuthentication } from 'authentication'

export function RedirectToLogin() {
  const query = useQuery()

  const { token: tokenInState } = useAuthentication()
  const tokenInQuery = query.get('token')
  const tokenInLocalStorage = localStorage.getItem('token')

  const token = tokenInState || tokenInQuery || tokenInLocalStorage

  apiAsync.setAuthToken(token)

  if (!token) {
    window.location = `${window.waAuthenticationUrl}login?redirectOrigin=${window.location.href}`
  }

  return null
}

export default RedirectToLogin
