import { Button, Icon, Popup, Text } from '@yes.technology/react-toolkit'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18n-lite'

interface GeolocationPermissionProps {
  onChange: (isGranted: boolean) => void
  isOpen: boolean
}

function GeolocationPermission({
  onChange,
  isOpen
}: GeolocationPermissionProps) {
  const { t } = useTranslation()

  const handleAllow = useCallback(() => {
    navigator.geolocation &&
      navigator.geolocation.getCurrentPosition(
        () => {
          onChange(true)
        },
        () => onChange(false)
      )
  }, [onChange])

  return (
    <Popup isOpen={isOpen} onClose={() => onChange(false)}>
      <div className='d-flex flex-column gap-4 align-items-center'>
        {/*TODO: doubleXLarge won't work */}
        <Icon iconName='Location' icSize='xLarge' icColor='_006BFF' />
        <Text variant='content-05'>{t('geolocation-modal.continue-text')}</Text>
        <Button variant='primary' heightClass='large' onClick={handleAllow}>
          {t('geolocation-modal.grant-button')}
        </Button>
        <Button
          variant='secondary'
          heightClass='large'
          onClick={() => onChange(false)}
        >
          {t('geolocation-modal.deny-button')}
        </Button>
      </div>
    </Popup>
  )
}

export default GeolocationPermission
