import moment from 'moment'
import validateActivityValue from './validateActivityValue'
import api from './api'
import { getOnlyValidItemGroups } from '../modules/interaction/utils/validation'
import { deepClone } from './helpers/object'
import { datepickerToISOFormat } from '@yes.technology/react-toolkit'

function interactionModelItemGroupCallback(interactionModelItemGroup) {
  const activityModels = interactionModelItemGroup.activity_models.map((a) => {
    return {
      uuid_activity_model: a.uuid,
      value: a.value ?? ''
    }
  })
  const itemGroup = {
    uuid_interaction_model_item_groups: interactionModelItemGroup.uuid,
    activity_models: activityModels
  }
  return itemGroup
}

function formatInteractionModelCallback(interactionModel) {
  const interactionModelItemGroups =
    interactionModel.interaction_model_item_groups.map((g) => {
      return interactionModelItemGroupCallback(g)
    })

  const requestBody = {
    uuid_interaction_model: interactionModel.uuid,
    interaction_model_item_groups: interactionModelItemGroups
  }
  return requestBody
}

function addActivityModels(activityModels, groups) {
  const firstActivityModel = deepClone(activityModels[0])

  for (let count = groups.length - activityModels.length; count > 0; count--) {
    activityModels.push(
      firstActivityModel.map((activity) => {
        delete activity.helperClass
        delete activity.helperText
        delete activity.validationStatus
        delete activity.value

        return {
          ...activity,
          uuid: activity.originalUuid + '-' + activityModels.length
        }
      })
    )
  }
}

function setActivityGroupValue(activityModel, value) {
  const groups = value && JSON.parse(value)

  if (!groups) {
    return activityModel
  }

  if (
    activityModel.activity_models.length > 0 &&
    activityModel.activity_models.length < groups.length
  ) {
    addActivityModels(activityModel.activity_models, groups)
  }

  activityModel.activity_models.forEach((group) => {
    groups.forEach((newGroup) => {
      group.forEach((groupActivityModel) => {
        newGroup.forEach((newGroupActivity) => {
          if (
            groupActivityModel.uuid === newGroupActivity.uuid_activity_model
          ) {
            setActivityModelValue(groupActivityModel, newGroupActivity)
          }
        })
      })
    })
  })
}

function isValidActivityModel(activityModel) {
  return activityModel.validationStatus === 'valid' || !activityModel.required
}

function isValidActivityModelGroup(activityModelGroup) {
  return activityModelGroup.every(isValidActivityModel)
}

function getGroupValidationStatus(activityModelGroup) {
  const allGroupsAreValid = activityModelGroup.activity_models.every(
    isValidActivityModelGroup
  )
  return allGroupsAreValid ? 'valid' : 'invalid'
}

const objectSelections = [
  'explicit_uniselection_objectclass',
  'explicit_multiselection_objectclass',
  'standard_multiselection',
  'standard_uniselection'
]

function isObjectSelection(contentType) {
  return objectSelections.includes(contentType)
}

function isContentTypeObject(activityModel) {
  return (
    activityModel?.information_request_filteraction ||
    isObjectSelection(activityModel.content_type)
  )
}

function setActivityModelValue(activityModel, activity) {
  const isActivityGroup = activityModel.content_type === 'activity_group'

  if (isActivityGroup) {
    setActivityGroupValue(activityModel, activity.des)
    activityModel.validationStatus = getGroupValidationStatus(activityModel)
    return
  }

  const activityValue = activity.des
    ? { id: activity.des, des: activity.complement || activity.des }
    : ''

  const contentIsObject = isContentTypeObject(activityModel)

  activityModel.value = contentIsObject ? activityValue : activity.des

  const { validationStatus, helperText } = validateActivityValue({
    activityModel
  })

  activityModel.validationStatus = validationStatus
  activityModel.helperText = helperText
}

export function setInteractionModelActivities(interactionModel, activities) {
  const interactionModelClone = deepClone(interactionModel)
  const steps = interactionModelClone.interaction_model_item_groups

  activities.forEach((activity) => {
    steps?.forEach((step) => {
      const newActivityModels = JSON.parse(JSON.stringify(step.activity_models))
      newActivityModels.forEach((activityModel) => {
        if (
          activityModel.uuid === activity.uuid_activity_model &&
          activityModel.value !== activity.des
        ) {
          setActivityModelValue(activityModel, activity)
        }
      })
      step.activity_models = newActivityModels
    })
  })

  return {
    ...interactionModelClone,
    interaction_model_item_groups: steps
  }
}

function getActivityFromActivityGroup(activityModel) {
  const groups = activityModel.activity_models

  const groupsActivities = groups.map((activityModels) => {
    const activities = activityModels.map(getActivityFromActivityModel)
    const nonEmptyActivities = activities.filter((activity) => activity.des)
    return nonEmptyActivities
  })

  const nonEmptyGroups = groupsActivities.filter((group) => group.length > 0)

  const activityValue =
    nonEmptyGroups.length > 0 ? JSON.stringify(nonEmptyGroups) : ''

  return {
    uuid_activity_model: activityModel.uuid,
    des: activityValue
  }
}

export function getActivityFromActivityModel(activityModel) {
  const isActivityGroup = activityModel.content_type === 'activity_group'

  if (isActivityGroup) {
    return getActivityFromActivityGroup(activityModel)
  }

  const contentIsObject = isContentTypeObject(activityModel)

  const uuid = activityModel?.value?.id
  const description = activityModel?.value?.des || activityModel.value || ''

  return {
    uuid_activity_model: activityModel.uuid,
    des: contentIsObject ? uuid : description,
    ...(contentIsObject && { complement: activityModel?.value?.des })
  }
}

export function getActivitiesFromInteractionModel(interactionModel) {
  const activities = []

  getOnlyValidItemGroups(
    interactionModel.data.interaction_model_item_groups
  ).forEach((step) => {
    const stepActivities = step.activity_models.map(
      getActivityFromActivityModel
    )

    const nonEmptyActivities = stepActivities.filter((object) => object.des)
    activities.push(...nonEmptyActivities)
  })

  return activities
}

// Retorna os uuids das activityModels do tipo especificado
export function getUuidsForContentType(itemGroups, contentType) {
  let uuids = []
  itemGroups.forEach((items) => {
    items.activity_models.forEach((activityModel) => {
      if (activityModel.content_type === contentType) {
        uuids.push(activityModel.uuid)
      }
    })
  })
  return uuids
}

// Troca o conteúdo do campo "des" pelo uuid do documento (document - objectclass)
export function handleDocumentPhoto(activities, itemGroups) {
  const uuids = getUuidsForContentType(itemGroups, 'document_photo')
  if (uuids.length > 0) {
    uuids.forEach((uuid) => {
      activities.find((activity, index) => {
        if (activity.uuid_activity_model === uuid) {
          const des = JSON.parse(activity.des)
          activities[index] = {
            ...activity,
            des: des.uuid
          }
        }
      })
    })
  }
  return activities
}

export function handleDate(activities, itemGroups, language) {
  const dateUuids = getUuidsForContentType(itemGroups, 'date')
  const dateTimeUuids = getUuidsForContentType(itemGroups, 'datetime')
  const uuids = [...dateUuids, ...dateTimeUuids]

  if (!uuids.length) {
    return activities
  }

  return activities.map((activity) => {
    if (uuids.includes(activity.uuid_activity_model)) {
      return { ...activity, des: datepickerToISOFormat(activity.des, language) }
    }
    return activity
  })
}

export function formatInteractionPost(interactionModel, language) {
  let activities = getActivitiesFromInteractionModel(interactionModel)

  /*
    Tratamento especial para a activity model "document_photo";
    Deve manipular as activities trocando o conteúdo do campo "des"
    pelo uuid do documento (document - objectclass)
  */
  activities = handleDocumentPhoto(
    activities,
    interactionModel.data.interaction_model_item_groups
  )

  activities = handleDate(
    activities,
    interactionModel.data.interaction_model_item_groups,
    language
  )

  // coords em string
  const latitude = interactionModel.geolocation?.latitude?.toString() || ''
  const longitude = interactionModel.geolocation?.longitude?.toString() || ''

  const workflowUuid = interactionModel.workflowUuid && {
    uuid_workflow: interactionModel.workflowUuid
  }

  const body = [
    {
      ...workflowUuid,
      uuid_interaction_model: interactionModel.data.uuid,
      uuid_user_creation_row: interactionModel.user,
      uuid_user_modification_row: interactionModel.user,
      uuid_language: interactionModel.language,
      latitude: latitude,
      longitude: longitude,
      date_start_interaction: interactionModel.dateStartInteraction,
      date_end_interaction: moment().format(),
      activities
    }
  ]

  return body
}

export async function validateInteractionModelCallback(
  interactionModel,
  endpoint
) {
  const formatedInteractionModel =
    formatInteractionModelCallback(interactionModel)
  const res = await api.postInputValidation(
    formatedInteractionModel,
    endpoint,
    true
  )
  return res
}

export async function validateInteractionModelItemGroupCallback(
  interactionModelItemGroup,
  endpoint
) {
  const formatedInteractionModelItemGroup = interactionModelItemGroupCallback(
    interactionModelItemGroup
  )
  const res = await api.postInputValidation(
    formatedInteractionModelItemGroup,
    endpoint,
    true
  )
  return res
}

export async function saveInteraction(interactionModel, language) {
  const body = formatInteractionPost(interactionModel, language)
  const res = await api.postSaveInteraction(body, true)
  return res
}

export function removeActivityGroup({
  interactionModel,
  activityModelUuid,
  removedGroupIndex
}) {
  const steps = interactionModel.interaction_model_item_groups

  steps.forEach((step) => {
    step.activity_models.forEach((activityModel) => {
      if (activityModel.uuid === activityModelUuid) {
        activityModel.activity_models.splice(removedGroupIndex, 1)

        activityModel.activity_models.forEach((group, index) => {
          group.forEach((activityModel) => {
            activityModel.uuid = activityModel.originalUuid + `-${index}`
          })
        })

        activityModel.validationStatus = getGroupValidationStatus(activityModel)
      }
    })
  })

  return interactionModel
}

export function addActivityGroup({
  interactionModel,
  activityModelUuid,
  newActivityGroup
}) {
  const steps = interactionModel.interaction_model_item_groups

  steps.forEach((step) => {
    step.activity_models.forEach((activityModel) => {
      if (activityModel.uuid === activityModelUuid) {
        activityModel.activity_models.push(newActivityGroup)
      }
    })
  })

  return interactionModel
}

const uuidReducer = (uuidMap, current) => {
  if (!current.uuid) {
    return uuidMap
  }

  return {
    ...uuidMap,
    ...(current.content_type ? { [current.uuid]: current } : {}),
    ...(current.activity_models
      ? current.activity_models.flat().reduce(uuidReducer, {})
      : {})
  }
}

export function getActivitiesByUuid(interactionModel) {
  const { data = {} } = interactionModel
  const { interaction_model_item_groups: modelItems = [] } = data

  return modelItems.reduce(uuidReducer, {})
}
