import apiAsync from '../../../utils/api-async'

export const executeBlueprints = ({
  interactionModelUuid,
  workflowUuid,
  activities,
  showLoading = false
}) => {
  return apiAsync.requestSync({
    endpoint: 'blueprints',
    method: 'POST',
    obj: {
      interactionmodel_uuid: interactionModelUuid,
      workflow_uuid: workflowUuid,
      activities
    },
    showLoading: showLoading,
    throwError: true
  })
}
