import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { interactionModelOperations } from '../redux'

const useInteractionModel = ({ currentTime }) => {
  const dispatch = useDispatch()
  const { id_interaction_model: idInteractionModel } = useParams()

  const getInteractionModel = useCallback(() => {
    dispatch(interactionModelOperations.getInteractionModel(idInteractionModel))
  }, [dispatch, idInteractionModel])

  const {
    interactionModel,
    interactionModel: { error }
  } = useSelector(({ interactions }) => interactions)

  useEffect(() => {
    const interactionModelIdChanged =
      idInteractionModel !== interactionModel?.data?.uuid

    if (interactionModelIdChanged) {
      getInteractionModel()
    }
  }, [getInteractionModel, idInteractionModel, interactionModel?.data?.uuid])

  useEffect(() => {
    if (interactionModel.interactionFinished) {
      dispatch(
        interactionModelOperations.setConfigToNewInteraction(currentTime)
      )
    }
  }, [interactionModel, currentTime, dispatch])

  return {
    interactionModel,
    interactionModelError: error,
    getInteractionModel,
    isFetchingInteractionModel: interactionModel?.isFetching
  }
}

export default useInteractionModel
