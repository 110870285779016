import { getOnlyValidItemGroups } from '../../../utils/validation'
import { InteractionModelItemGroup } from 'types/shared'
import { useAppSelector } from 'state/store'

const useInteraction = () => {
  const {
    interactionModel: {
      data: { interaction_model_item_groups: interactionModelItemGroups },
      user,
      dateStartInteraction,
      geolocation
    }
  } = useAppSelector(({ interactions }) => interactions)

  return {
    interactionModelItemGroups: getOnlyValidItemGroups(
      interactionModelItemGroups
    ) as InteractionModelItemGroup[],
    user,
    dateStartInteraction,
    geolocation
  }
}

export default useInteraction
