import Symbol from './Symbol'

export const Screen = ({ rows = 6, columns = 4, symbols = [] }) => {
  const calculateColumnWidthPercentage = (n) => {
    return 100 / n
  }

  return (
    <div className='yta-screen'>
      {new Array(rows).fill('').map((_, rowIndex) => {
        return (
          <div key={`rowIndex-${rowIndex}`} className='yta-row'>
            {new Array(columns).fill('').map((_, colIndex) => {
              return (
                <div
                  key={`colIndex-${colIndex}`}
                  className='yta-column'
                  style={{
                    width: `${calculateColumnWidthPercentage(columns)}%`
                  }}
                >
                  <Symbol
                    symbol={symbols[getSymbolIndex(rowIndex, colIndex)]}
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Screen
