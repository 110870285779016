import { useCallback, useEffect, useRef, useState } from 'react'

// FIXME remove this hook and replace current usage with `useState`
/**
 * @deprecated This hook was made probably because React
 * used to throw warnings for setState on unmounted components.
 * That behavior was revoked.
 * See https://github.com/reactwg/react-18/discussions/82
 */
function useAsyncState(initialState = undefined) {
  const isMounted = useRef(true)

  const onUnmount = () => {
    isMounted.current = false
  }

  useEffect(() => onUnmount, [])

  const [state, setState] = useState(initialState)

  const setStateIfMounted = useCallback(
    (...newStateProps) => {
      isMounted.current && setState(...newStateProps)
    },
    [setState]
  )

  return [state, setStateIfMounted]
}

export default useAsyncState
