import { useState } from 'react'
import { useTranslation } from 'react-i18n-lite'

import useModal from '../../../shared/hooks/useModal'
import Progress from '../../../shared/Progress'
import WebcamStreamCapture from '../../../shared/WebcamStreamCapture'
import Player from '../../../shared/WebcamStreamCapture/components/Player'
import useUploadS3 from '../../hooks/useUploadS3'

const ActivityInputTakeVideo = ({ inputValue, onChange, label }) => {
  const { t } = useTranslation()

  const [videoPreview, setVideoPreview] = useState('')

  const {
    isOpenModal: isOpenCamera,
    openModal: openCamera,
    closeModal: closeCamera
  } = useModal()

  const handleCapture = (file) => {
    closeCamera()
    setVideoPreview(file)
  }

  const { progress, currentStatus, setCurrentStatus, uploadFile } =
    useUploadS3()

  const removeFile = () => {
    onChange('')
    setVideoPreview('')
    setCurrentStatus(t('activity-input-upload-s3.status.awaiting-upload'))
  }

  const handleUploadFile = () => {
    uploadFile({
      file: videoPreview,
      onStart: () => {},
      onSuccess: handleOnSuccess,
      onError: handleOnError,
      fileType: 'blob'
    })
  }

  const handleOnSuccess = (url) => {
    onChange(url)
  }

  const handleOnError = (err) => {
    console.log(err)
  }

  return (
    <>
      {label && <label>{label}</label>}
      <div className='mt-2 mb-3'>
        {!videoPreview && !inputValue && (
          <button className='btn btn-light btn-sm' onClick={openCamera}>
            {t('activity-input-take-video.btn-take-a-video')}
          </button>
        )}

        {videoPreview && !inputValue && <Player source={videoPreview} />}

        {(inputValue || videoPreview) && (
          <div className='card mt-2'>
            <div className='card-body'>
              {progress > 0 && progress < 100 && (
                <Progress valueNow={progress} heightProgress={2} />
              )}
              <div>
                {t('activity-input-upload-s3.status-des')}:{' '}
                <strong>{currentStatus}</strong>
              </div>
              {inputValue && (
                <div className='border-top pt-2 mt-2'>
                  {t('activity-input-upload-s3.saved-file-des')}:{' '}
                  <strong>{inputValue}</strong>{' '}
                  <button
                    type='button'
                    className='btn btn-link btn-sm link-danger'
                    onClick={removeFile}
                  >
                    {t('activity-input-upload-s3.btn-remove-file-des')}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        {videoPreview && !inputValue && (
          <div className='btn-group mt-2'>
            <button className='btn btn-dark btn-sm' onClick={handleUploadFile}>
              {t('activity-input-take-video.btn-upload-des')}
            </button>

            <button
              className='btn btn-danger btn-sm'
              onClick={() => setVideoPreview('')}
            >
              {t('activity-input-take-video.btn-discard')}
            </button>
          </div>
        )}
      </div>

      {isOpenCamera && (
        <WebcamStreamCapture onSuccess={handleCapture} onCancel={closeCamera} />
      )}
    </>
  )
}

export default ActivityInputTakeVideo
