const findActivityModelWithDecisionModel = (interactionModel) => {
  const steps = interactionModel?.interaction_model_item_groups
  const allActivityModels = steps?.map((step) => step.activity_models).flat()

  return allActivityModels?.find(
    (activityModel) => activityModel.sequence_decision_model
  )
}

const hasPendingDecisionModel = (interactionModel) => {
  const activityModelWithDecisionModel =
    findActivityModelWithDecisionModel(interactionModel)

  return (
    Boolean(activityModelWithDecisionModel) &&
    !activityModelWithDecisionModel?.value
  )
}

export default hasPendingDecisionModel
