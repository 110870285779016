import {
  Button,
  DocumentListCard,
  Icon,
  Unifree
} from '@yes.technology/react-toolkit'
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18n-lite'

import {
  formatDate,
  getLocaleByLanguage
} from '../../../../../../utils/helpers/date'
import GeolocationPermission from '../GeolocationPermission'
import GeolocationModal from 'modules/shared/GeolocationModal/GeolocationModal'

enum ORIGIN {
  AUTO = 'step.review.location.origin.auto',
  SEMI = 'step.review.location.origin.semi',
  MANUAL = 'step.review.location.origin.manual',
  NONE = 'step.review.location.origin.none'
}

const MARKER_ID = 'current-user-location'

interface InteractionDataProps {
  coords?: GeolocationCoordinates
  onCoordsChange: (coords: GeolocationCoordinates) => void
  dateStartInteraction: string
  user: string
}

function InteractionData({
  coords,
  onCoordsChange,
  dateStartInteraction,
  user
}: InteractionDataProps) {
  const { t, language } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAdjustOpen, setIsAdjustOpen] = useState(false)
  const [origin, setOrigin] = useState<ORIGIN>(ORIGIN.NONE)
  const [isLocationGranted, setIsLocationGranted] = useState(false)

  useEffect(() => {
    navigator.permissions &&
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          navigator.geolocation &&
            navigator.geolocation.getCurrentPosition((success) => {
              setIsLocationGranted(true)
              setIsModalOpen(false)
              setOrigin(ORIGIN.AUTO)
              onCoordsChange(success.coords)
            })
        } else if (result.state === 'prompt') {
          setIsModalOpen(true)
        } else {
          setIsModalOpen(false)
          setOrigin(ORIGIN.NONE)
        }
      })
  }, [onCoordsChange])

  useEffect(() => {
    navigator.geolocation &&
      isLocationGranted &&
      navigator.geolocation.getCurrentPosition((pos) => {
        onCoordsChange(pos.coords)
      })
  }, [isLocationGranted, onCoordsChange])

  const handleModalPermission = useCallback((isGranted: boolean) => {
    setIsModalOpen(false)
    setIsLocationGranted(isGranted)
    setOrigin(isGranted ? ORIGIN.AUTO : ORIGIN.NONE)
  }, [])

  const handleLocationButton = useCallback(() => {
    setIsAdjustOpen(true)
  }, [])

  const handleAdjustClose = useCallback(() => setIsAdjustOpen(false), [])

  const handleAdjustChange = useCallback(
    (value: string) => {
      setOrigin(isLocationGranted ? ORIGIN.SEMI : ORIGIN.MANUAL)

      const [lat, lng] = (value || '0,0').split(',')
      onCoordsChange({
        latitude: Number(lat),
        longitude: Number(lng)
      } as GeolocationCoordinates)
    },
    [onCoordsChange, isLocationGranted]
  )

  const { latitude, longitude } = coords || {}

  const geoLocValue = (latitude && longitude && `${latitude},${longitude}`) as
    | string
    | undefined

  const locale = getLocaleByLanguage(language)

  return (
    <>
      <GeolocationPermission
        isOpen={isModalOpen}
        onChange={handleModalPermission}
      />

      <DocumentListCard className='mt-2'>
        <h2 className='mt-2 mb-3'>{t('step.review.basic-interaction-data')}</h2>

        <Unifree
          informational
          heightClass='large'
          label={t('step.review.user')}
          value={user}
        />

        {dateStartInteraction && (
          <Unifree
            informational
            heightClass='large'
            label={t('step.review.datetime')}
            value={formatDate(dateStartInteraction, 'dd/MM/yyyy | p', {
              locale
            })}
          />
        )}

        <Unifree
          informational
          heightClass='large'
          label={t('step.review.location.status')}
          value={
            isLocationGranted || coords
              ? t('step.review.location.granted')
              : t('step.review.location.denied')
          }
        />

        <Unifree
          informational
          heightClass='large'
          label={t('step.review.location.origin.label')}
          value={t(origin)}
        />

        <Unifree
          informational
          heightClass='large'
          label={t('step.review.location.lat')}
          value={
            latitude
              ? Number(latitude).toString()
              : t('step.review.location.404-lat')
          }
        />

        <Unifree
          informational
          heightClass='large'
          label={t('step.review.location.lng')}
          value={
            longitude
              ? Number(longitude).toString()
              : t('step.review.location.404-lng')
          }
        />

        <Button
          variant='secondary'
          heightClass='large'
          onClick={handleLocationButton}
          className='mb-2 position-relative'
        >
          <Icon
            iconName='Location'
            icColor='_006BFF'
            icSize='large'
            className='position-absolute'
            style={{ left: 8 }}
          />
          {coords
            ? t('step.review.location.adjust')
            : t('step.review.location.add')}
        </Button>

        <GeolocationModal
          open={isAdjustOpen}
          isAddition={!coords}
          handleClose={handleAdjustClose}
          editByClick
          {...{
            id: MARKER_ID,
            value: geoLocValue,
            onChange: handleAdjustChange
          }}
        />
      </DocumentListCard>
    </>
  )
}

export default InteractionData
