import { v4 as uuidv4 } from 'uuid'
import fetch from 'cross-fetch'

interface Config {
  [key: string]: string
}

interface Params {
  customEndpoint?: boolean
  endpoint: string
  path?: string
  showLoading?: boolean
  method?: string
  obj?: unknown
  secretKey?: string
  throwError?: boolean
}

interface Dispatch {
  (action: { type: string; data: { requestUuid: string } }): void
}

class ApiAsync {
  private config!: Config
  private dispatch!: Dispatch
  private authToken?: string
  private lang?: string

  setConfig(config: Config) {
    this.config = config
  }

  setDispatch(dispatch: Dispatch) {
    this.dispatch = dispatch
  }

  setAuthToken(authToken: string) {
    this.authToken = authToken
  }

  async fetchWithToken(params: Params, requestUuid: string) {
    let urlApiMS = ''

    if (params.customEndpoint) {
      urlApiMS = params.endpoint
    } else {
      urlApiMS = this.config[params.endpoint]
    }

    if (params.path) {
      urlApiMS = urlApiMS + params.path
    }

    if (undefined === params.showLoading) {
      params.showLoading = true
    }

    const headers: { [key: string]: string } = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': this.getAcceptLanguage()
      // 'Accept-Encoding': 'gzip, compress, br'
    }

    if (this.authToken) {
      headers.AUTH_TOKEN = this.authToken
    }

    // temporário para utilizar um novo serviço de mock api
    if (params.secretKey) {
      headers['secret-key'] = params.secretKey
    }

    if (params.showLoading) {
      this.dispatch({
        type: 'api/REQUESTED',
        data: {
          requestUuid: requestUuid
        }
      })
    }

    const response = await fetch(urlApiMS, {
      method: params.method || 'GET',
      headers: headers,
      body: params.method !== 'GET' ? JSON.stringify(params.obj) : undefined
    })

    return response
  }

  async requestSync(params: Params) {
    const requestUuid = uuidv4()
    try {
      const response = await this.fetchWithToken(params, requestUuid)

      if (params.showLoading) {
        this.dispatch({
          type: 'api/RECEIVED',
          data: {
            requestUuid: requestUuid
          }
        })
      }

      if (params.throwError && !response.ok) {
        throw response
      }

      return await response.json()
    } catch (e) {
      if (params.showLoading) {
        this.dispatch({
          type: 'api/FAILED',
          data: {
            requestUuid: requestUuid
          }
        })
      }

      throw e
    }
  }

  setAcceptLanguage(lang: string) {
    this.lang = lang
  }

  getAcceptLanguage(): string {
    switch (this.lang) {
      case 'pt-BR':
        return 'pt-BR, en-US'
      case 'de':
        return 'de, en-US'
      default:
        return 'en-US'
    }
  }
}

export default new ApiAsync()
