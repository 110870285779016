import React, { createContext, useCallback, useState } from 'react'
import { AuthenticationData } from 'types/shared'

type AuthContextType = AuthenticationData & {
  updateAuthData: (authenticationData: AuthenticationData) => void
  logout: () => void
}

const initialAuthContext: AuthContextType = {
  updateAuthData: () => {},
  logout: () => {}
}

export const AuthenticationContext =
  createContext<AuthContextType>(initialAuthContext)

type AuthProviderProps = { children: React.ReactNode }

export default function AuthenticationProvider({
  children
}: AuthProviderProps) {
  const [authenticationData, setAuthenticationData] =
    useState<AuthenticationData>()

  const updateAuthData = useCallback(
    (newAuthenticationData: AuthenticationData) => {
      localStorage.setItem('token', newAuthenticationData.token || '')
      setAuthenticationData(newAuthenticationData)
    },
    []
  )

  const logout = useCallback(() => {
    localStorage.removeItem('token')
    setAuthenticationData(undefined)
  }, [])

  return (
    <AuthenticationContext.Provider
      value={{ ...authenticationData, updateAuthData, logout }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
