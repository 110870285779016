import { useCallback, useState } from 'react'

import api from '../../../utils/api'
import { InteractionModelItemGroup } from 'types/shared'
import { YesObject } from '@yes.technology/react-toolkit'
import { useDispatch } from 'react-redux'
import { Response } from 'cross-fetch'
import { FilteractionParams } from 'utils/apiTypes'

type AutocompleteResponse = {
  data:
    | YesObject[]
    | {
        objects: YesObject[]
      }
  message: string
  success: boolean
}

type UseFetchSuggestionsProps = {
  id: string
  filteraction?: string
  showLoading?: boolean
  endpoint?: string
  filteractionParams?: object[]
}

export default function useFetchSuggestions({
  id,
  filteractionParams,
  filteraction,
  endpoint,
  showLoading = false
}: UseFetchSuggestionsProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [suggestions, setSuggestions] = useState<YesObject[]>([])
  const dispatch = useDispatch()

  const resetSuggestions = () => {
    setSuggestions([])
  }

  const fetchSuggestions = useCallback(
    async (
      value: string,
      interactionModelItemGroupsState?: InteractionModelItemGroup[]
    ) => {
      try {
        setIsLoading(true)
        const response: AutocompleteResponse = await api.getAutocomplete(
          value,
          endpoint || '',
          showLoading,
          filteraction,
          filteractionParams as FilteractionParams[],
          interactionModelItemGroupsState || [],
          id
        )

        if (response?.success === true) {
          let results = response.data

          if ('objects' in response.data) {
            results = response.data.objects
          }

          if (!Array.isArray(results)) {
            results = []
          }

          setSuggestions(results)
        } else {
          throw new Error(response?.message || 'Response vazia ou com erro')
        }
      } catch (error) {
        if (error instanceof Response) {
          dispatch({ type: 'api/ERROR', data: { status: error.status } })
        }

        console.error(error)
      } finally {
        setIsLoading(false)
      }
    },
    [endpoint, filteraction, filteractionParams, id, showLoading, dispatch]
  )

  return { suggestions, isLoading, fetchSuggestions, resetSuggestions }
}
