import { useEffect } from 'react'

import useAsyncState from '../../../utils/useAsyncState'
import {
  apiSituation,
  apiSituationAction
} from '../../../utils/apiSituationStatusAndActions'

const hasObjects = (response) =>
  Boolean(
    response &&
      response.success &&
      response.data?.objects &&
      response.data.objects.length > 0
  )

export default function useSituation({ objectClass, idObject, situationCode }) {
  const [objectSituation, setObjectSituation] = useAsyncState()
  const [situations, setSituations] = useAsyncState([])
  const [situationActions, setSituationActions] = useAsyncState([])
  const [visibleSituationActions, setVisibleSituationActions] = useAsyncState(
    []
  )
  const [isFetchingSituations, setIsFetchingSituations] = useAsyncState(true)
  const [isFetchingActions, setIsFetchingActions] = useAsyncState(true)
  const [isLoading, setIsLoading] = useAsyncState(true)

  const fetchSituations = () => {
    apiSituation({ loading: false })
      .then((response) => {
        setSituations(response?.data?.objects || [])
      })
      .finally(() => {
        setIsFetchingSituations(false)
      })
  }

  const fetchSituationActions = () => {
    if (!isFetchingSituations) {
      const requestFilters = {
        'destination_situation.uuid': {
          $in: situations.map((situation) => situation.uuid)
        }
      }

      const performFetch = () =>
        apiSituationAction({ $and: requestFilters, loading: false })
          .then((response) => {
            if (hasObjects(response)) {
              setSituationActions(response.data.objects)
            }
          })
          .finally(() => {
            setIsFetchingActions(false)
          })

      situations.length ? performFetch() : setIsFetchingActions(false)
    }
  }

  const updateObjectSituation = () => {
    const initialSituationAction = situationActions.find(
      (situationAction) => !situationAction.allowed_origin_situation
    )
    const initialSituation = initialSituationAction?.destination_situation?.[0]

    const objectSituationCode = idObject
      ? situationCode
      : initialSituation?.code
    const newObjectSituation = situations.find(
      ({ code }) => code === objectSituationCode
    )

    objectSituationCode && setObjectSituation(newObjectSituation)
  }

  const updateVisibleSituationActions = () => {
    if (objectSituation && !isFetchingActions) {
      const getVisibleActions = (situationAction) =>
        situationAction.allowed_origin_situation?.[0]?.code ===
        objectSituation.code
      const newVisibleSituationActions =
        situationActions.filter(getVisibleActions)
      setVisibleSituationActions(newVisibleSituationActions)
      setIsLoading(false)
    }
  }

  useEffect(fetchSituations, [
    objectClass,
    setIsFetchingSituations,
    setSituations
  ])

  useEffect(fetchSituationActions, [
    situations,
    isFetchingSituations,
    setIsFetchingActions,
    setSituationActions
  ])

  useEffect(updateObjectSituation, [
    situationActions,
    situations,
    idObject,
    situationCode,
    setObjectSituation
  ])

  useEffect(updateVisibleSituationActions, [
    situationActions,
    objectSituation,
    isFetchingActions,
    setIsLoading,
    setVisibleSituationActions
  ])

  return { objectSituation, visibleSituationActions, isLoading }
}
