import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18n-lite'

import { interactionModelOperations } from '../redux'
import { useAuthentication } from 'authentication'

const useInitialConfig = (interactionModel) => {
  const dispatch = useDispatch()

  const { username } = useAuthentication()

  const { language: lang } = useTranslation()

  useEffect(() => {
    const { user, dateStartInteraction, language } = interactionModel

    if (!user && username && !dateStartInteraction && !language) {
      dispatch(
        interactionModelOperations.setInitialConfiguration(
          username,
          moment().format(),
          lang
        )
      )
    }
  }, [dispatch, interactionModel, username, lang])

  return null
}

export default useInitialConfig
