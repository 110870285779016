import { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { Button, Icon } from '@yes.technology/react-toolkit'

import useWindowSize from './hooks/useWindowSize'
import '../../../asset/css/webcam.css'
import { useTranslation } from 'react-i18n-lite'

const FACING_MODE_USER = 'user'
const FACING_MODE_ENVIRONMENT = 'environment'

const WebcamCapture = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation()

  const webcamRef = useRef(null)

  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT)
  const [devices, setDevices] = useState([])
  const [image, setImage] = useState(null)

  const handleDevices = useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices]
  )

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices)
  }, [handleDevices])

  const handleChangeDevice = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    )
  }, [])

  const size = useWindowSize()
  const isLandscape = size.height <= size.width
  const ratio = isLandscape
    ? size.width / size.height
    : size.height / size.width

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImage(imageSrc)
  }, [webcamRef])

  const handleOnSubmit = () => {
    onSuccess(image)
    setImage(null)
  }

  const handleOnCancel = () => {
    onCancel()
  }

  return (
    <div
      className='webcam'
      style={{
        width: size.width,
        height: size.height,
        background: '#ffffff',
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: 2
      }}
    >
      <div className='position-relative'>
        <div className='webcam-header'>
          <div className='webcam-header-elements'>
            <button
              type='button'
              className='icon-button'
              onClick={handleOnCancel}
            >
              <Icon iconName='Close' icSize='small' />
            </button>
            <span>
              {image
                ? t('activity-document-photo.webcam.preview')
                : t('activity-document-photo.webcam.camera')}
            </span>
          </div>
        </div>

        {image === null ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            height={size.height}
            width={size.width}
            videoConstraints={{
              aspectRatio: ratio,
              facingMode
            }}
          />
        ) : (
          <img src={image} alt='screenshot' />
        )}

        <div className='webcam-footer'>
          {image === null ? (
            <div className='webcam-footer-controls webcam-footer-controls-before-capture'>
              <button type='button' className='icon-button' disabled>
                <Icon iconName='CameraFlashOff' />
              </button>
              <button
                type='button'
                className='button-take-photo'
                onClick={capture}
              />
              <button
                type='button'
                className='icon-button'
                onClick={handleChangeDevice}
                disabled={devices.length === 1}
              >
                <Icon iconName='SwitchCamera' />
              </button>
            </div>
          ) : (
            <div className='webcam-footer-controls webcam-footer-controls-after-capture'>
              <Button onClick={handleOnSubmit}>
                {t('activity-document-photo.webcam.continue')}
              </Button>
              <Button variant='secondary' onClick={() => setImage(null)}>
                {t('activity-document-photo.webcam.discard')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default WebcamCapture
