import { Icon } from '@yes.technology/react-toolkit'
import useWindowSize from '../WebcamCapture/hooks/useWindowSize'
import { useTranslation } from 'react-i18n-lite'

const WebcamView = ({ image, onCancel }) => {
  const { t } = useTranslation()
  const size = useWindowSize()
  return (
    <div
      className='webcam'
      style={{
        width: size.width,
        height: size.height,
        background: '#ffffff',
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: 2
      }}
    >
      <div className='position-relative'>
        <div className='webcam-header-view'>
          <div className='webcam-header-elements'>
            <button type='button' className='icon-button' onClick={onCancel}>
              <Icon iconName='Close' icSize='small' />
            </button>
            <span>{t('activity-document-photo.webcam.view')}</span>
          </div>
        </div>
        <div className='h-100 d-flex justify-content-center align-items-center'>
          <img src={image} className='img-fluid' alt='screenshot' />
        </div>
      </div>
    </div>
  )
}

export default WebcamView
