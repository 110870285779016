import { useDispatch } from 'react-redux'

import { useQuery } from '../useQuery'
import { interactionModelOperations } from '../../interaction/redux'
import { useEffect } from 'react'

export const useWorkflow = () => {
  const query = useQuery()
  const workflowUuid = query.get('uuid_workflow')
  const dispatch = useDispatch()

  useEffect(() => {
    if (workflowUuid) {
      dispatch(interactionModelOperations.setWorkflow(workflowUuid))
    }
  }, [dispatch, workflowUuid])
}

export default useWorkflow
