import { useDispatch } from 'react-redux'
import { interactionModelOperations } from '../redux'
import { deepClone } from 'utils/helpers/object'

const useSequenceRules = () => {
  const dispatch = useDispatch()

  const hideStep = (stepUuids, itemGroups) => {
    itemGroups.forEach((itemGroup) => {
      if (stepUuids.includes(itemGroup.uuid)) {
        itemGroup.show = false
      }
    })
  }

  const showStep = (stepUuids, itemGroups) => {
    itemGroups.forEach((itemGroup) => {
      if (stepUuids.includes(itemGroup.uuid)) {
        itemGroup.show = true
      }
    })
  }

  const executeSequenceDecisionModel = (
    currentActivity,
    currentActivityModels,
    itemGroups
  ) => {
    const actionsList = currentActivityModels.map((activityModel) => {
      if (currentActivity.uuid_activity_model === activityModel.uuid) {
        return activityModel?.sequence_decision_model?.[currentActivity.des]
      }
      return null
    })

    if (actionsList.length === 0) {
      return
    }

    const itemGroupsClone = deepClone(itemGroups)

    actionsList.forEach((actions) => {
      Array.isArray(actions) &&
        actions.forEach(({ action, step_uuids: stepUuids }) => {
          if (action === 'hide_step') hideStep(stepUuids, itemGroupsClone)
          if (action === 'show_step') showStep(stepUuids, itemGroupsClone)
        })
    })

    dispatch(interactionModelOperations.updateItemGroups(itemGroupsClone))
  }

  return {
    executeSequenceDecisionModel
  }
}

export default useSequenceRules
