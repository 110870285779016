import { useEffect, useState, useId, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18n-lite'
import { Button, Icon } from '@yes.technology/react-toolkit'
import MapView from '../Map'

import LocationInput from './LocationInput'

const getModalPortal = () => {
  const portalId = 'modal-portal'
  const portal =
    document.getElementById(portalId) || document.createElement('div')

  if (!portal.parentElement) {
    portal.setAttribute('id', portalId)
    document.body.appendChild(portal)
  }

  return portal
}

const useBodyScrollLock = (lock) => {
  useEffect(() => {
    document.body.style.overflow = lock ? 'hidden' : 'auto'
  }, [lock])
}

export default function GeolocationModal({
  id,
  value = '',
  open = false,
  editByClick = false,
  isAddition,
  handleClose,
  onChange
}) {
  useBodyScrollLock(open)

  const [position, setMarkerPosition] = useState({
    lat: '0',
    lng: '0',
    initial: true
  })

  useEffect(() => {
    if (!open) {
      return
    }

    const [lat, lng] = (value || '0,0').split(',')
    setMarkerPosition({ lat, lng, initial: !value })
  }, [value, open])

  const marker = {
    ...position,
    uuid: id,
    onDragEnd: ({ latLng }) =>
      setMarkerPosition({ lat: latLng.lat(), lng: latLng.lng() })
  }

  const onClickContinue = useCallback(() => {
    onChange(`${position.lat},${position.lng}`)
    handleClose()
  }, [handleClose, onChange, position])

  const onMapClick = ({ latLng }) => {
    editByClick && setMarkerPosition({ lat: latLng.lat(), lng: latLng.lng() })
  }

  const { t } = useTranslation()

  const titleId = useId()

  if (!open) {
    return null
  }

  return createPortal(
    <div
      className='d-flex flex-column'
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10,
        height: '100%',
        width: '100%',
        background: '#fff'
      }}
      role='dialog'
      aria-labelledby={titleId}
    >
      <div className='d-flex align-items-center p-3'>
        <button
          type='button'
          style={{
            margin: 0,
            padding: 0,
            background: 'none',
            width: '18px',
            left: '16px',
            border: 0
          }}
          onClick={handleClose}
        >
          <Icon iconName='Close' icSize='xsmall' />
        </button>
        <h2
          id={titleId}
          style={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            margin: '0 4px'
          }}
        >
          View
        </h2>
      </div>
      {isAddition ? (
        <LocationInput onChange={setMarkerPosition} />
      ) : (
        <div className='d-flex align-items-center p-3 pt-0 pb-4'>
          <Icon iconName='Pin' icSize='small' icColor='_006BFF' />
          <span
            className='px-1'
            style={{ fontSize: '14px', lineHeight: '20px' }}
          >
            {t('activity-input-geolocation.drop-pin-info')}
          </span>
        </div>
      )}
      <MapView
        markers={[marker]}
        mapStyles={{ height: '100%' }}
        onMapClick={onMapClick}
      />
      <div className='p-3'>
        <div className='mb-2'>
          <Button onClick={onClickContinue} disabled={position.initial}>
            {t('activity-input-geolocation.labels.continue')}
          </Button>
        </div>
        <div>
          <Button variant='secondary' onClick={handleClose}>
            {t('activity-input-geolocation.labels.discard')}
          </Button>
        </div>
      </div>
    </div>,
    getModalPortal()
  )
}
