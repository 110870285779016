import { createContext, useContext, useState } from 'react'

export type ReviewSaveButtonContextType = {
  stickySaveVisible: boolean
  setStickySaveVisible: (value: boolean) => void
}

export const ReviewSaveButtonContext =
  createContext<ReviewSaveButtonContextType>({
    stickySaveVisible: false,
    setStickySaveVisible: () => void 0
  })

export const ReviewSaveButtonContextProvider = ({
  children
}: React.PropsWithChildren) => {
  const [stickySaveVisible, setStickySaveVisible] = useState(false)

  return (
    <ReviewSaveButtonContext.Provider
      value={{
        stickySaveVisible,
        setStickySaveVisible
      }}
    >
      {children}
    </ReviewSaveButtonContext.Provider>
  )
}

export const useReviewSaveButtonContext = () =>
  useContext(ReviewSaveButtonContext)
