import '../../asset/css/splash-screen.css'

const Error = ({ errorMessage }) => {
  return (
    <div className='d-flex flex-column'>
      <div className='align-self-center mt-5'>
        <h2>Opss!</h2>
      </div>
      <div className='align-self-center mt-5'>
        <p>{errorMessage}</p>
      </div>
    </div>
  )
}

export default Error
